import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ObservationListState {
  observationList: {
    load: boolean;
    data: [];
  };
}

export const observationListSlice = createSlice({
  name: "observationList",
  initialState: {
    observationList: {
      load: false,
      data: [],
    },
  } as ObservationListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchobservationLists.pending, (state: ObservationListState) => {
        state.observationList.load = true;
      })
      .addCase(fetchobservationLists.fulfilled, (state: ObservationListState, action: any) => {
        state.observationList.data = action.payload;
        state.observationList.load = false;
      })
      .addCase(fetchobservationLists.rejected, (state: ObservationListState) => {
        state.observationList.load = false;
      });
  },
});

export default observationListSlice.reducer;

export const fetchobservationLists = createAsyncThunk(
  "issue/fetchobservationLists",
  async (param: {apiParam: {issue_pin: any, page: any, per_page: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/viewObservationChecklist?verified_issue_pin=" + param.apiParam.issue_pin + "&page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page);
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);



