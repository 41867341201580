import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TestingSummaryState {
    testingsummary: {
    load: boolean;
    data: [];
  };
}

export const ReportedIssueSlice = createSlice({
  name: "testingsummary",
  initialState: {
    testingsummary: {
      load: false,
      data: [],
    },
  } as TestingSummaryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTestingSummary.pending, (state: TestingSummaryState) => {
        state.testingsummary.load = true;
      })
      .addCase(fetchTestingSummary.fulfilled, (state: TestingSummaryState, action: any) => {
        state.testingsummary.data = action.payload;
        state.testingsummary.load = false;
      })
      .addCase(fetchTestingSummary.rejected, (state: TestingSummaryState) => {
        state.testingsummary.load = false;
      })
      .addCase(fetchPrsesTestingSummary.pending, (state: TestingSummaryState) => {
        state.testingsummary.load = true;
      })
      .addCase(fetchPrsesTestingSummary.fulfilled, (state: TestingSummaryState, action: any) => {
        state.testingsummary.data = action.payload;
        state.testingsummary.load = false;
      })
      .addCase(fetchPrsesTestingSummary.rejected, (state: TestingSummaryState) => {
        state.testingsummary.load = false;
      });
  },
});

export default ReportedIssueSlice.reducer;

export const fetchTestingSummary = createAsyncThunk(
  "testingsummary/fetchTestingSummary",
  async (param: {apiParam: {project_pin: any, test_type: any, per_page: any, page: any, search: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getTestingSummaryData?project_pin=` + param.apiParam.project_pin + '&test_type=' + param.apiParam.test_type + `&per_page=` + param.apiParam.per_page + `&page=` + param.apiParam.page + "&search=" + param.apiParam.search);
      
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const fetchPrsesTestingSummary = createAsyncThunk(
  "testingsummary/fetchPrsesTestingSummary",
  async (param: {apiParam: {test_type: any, per_page: any, page: any, search: any, project_pin: any, warehouse_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getSyncTestingSummaryData?warehouse_id=` + param.apiParam.warehouse_id + "&project_pin=" + param.apiParam.project_pin + '&test_type=' + param.apiParam.test_type + `&per_page=` + param.apiParam.per_page + `&page=` + param.apiParam.page + "&search=" + param.apiParam.search);
      
      return data.data;
    } catch (error) {
      return error;
    }
  }
);


