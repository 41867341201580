import { createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import { ROLES } from "../../../utils/constant";
import { getItem, setItem, removeAll } from "../../../utils/getLocalStorage";
import { Navigate } from "react-router-dom";
import { toggleLoading } from "../LoadingSlice";
import CryptoJS from 'crypto-js';
import encryptData from "../../../reusable/encryptData";
import decryptData from "../../../reusable/decryptData";

export const authenticationSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      load: false,
      data: {
        id: 0,
        role_id: 0,
        company_id: null,
        created_by: 0,
        member_id: null,
        first_name: "",
        last_name: "",
        email: "",
        email_verified_at: null,
        contact_number: "",
        address_line1: "",
        address_line2: "",
        city: "",
        district_id: 0,
        state_id: 0,
        country_id: 0,
        pin_code: 0,
        profile_image: null,
        status: "",
        retrive_count: null,
        created_at: null,
        updated_at: null,
        joining_date: null,
      },
      token: "",
      message: "",
      status_code: null,
      role_id: 1,
      role: "",
      isAuthenticated: false,
    },
  },
  reducers: {
    userLoginRequest: (state) => {
      state.user.load = true;
    },
    userLoginSuccess: (state, action) => {
      state.user = action.payload;
      state.user.isAuthenticated = action.payload.isAuthenticated;
      state.user.load = false;
      state.user.role = action.payload.role;
    },
    userLoginFail: (state, action) => {
      state.user.isAuthenticated = action.payload.isAuthenticated;
      state.user.load = action.payload.load;
    },
    userSessionSet: (state, action) => {
      state.user.isAuthenticated = action.payload.isAuthenticated;
      state.user.data.id = action.payload.user_id;
      state.user.role = action.payload.role;
      state.user.data.profile_image = action.payload.profile_image;
      state.user.data.first_name = action.payload.first_name;
      state.user.data.last_name = action.payload.last_name;
      state.user.data.email = action.payload.email;
      state.user.data.contact_number = action.payload.contact_number;
      state.user.data.address_line1 = action.payload.address_line1;
      state.user.data.address_line2 = action.payload.address_line2;
      state.user.data.city = action.payload.city;
      state.user.data.country_id = action.payload.country_id;
      state.user.data.district_id = action.payload.district_id;
      state.user.data.state_id = action.payload.state_id;
      state.user.data.district_id = action.payload.district_id;
      state.user.data.pin_code = action.payload.pin_code;
    },
    loginUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  loginUser,
  userLoginRequest,
  userLoginSuccess,
  userLoginFail,
  userSessionSet,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const LoginUser =
  ({ email = "", password = "" }) =>
    async (dispatch: Function) => {
      try {
        dispatch(toggleLoading());
        dispatch(userLoginRequest());
        const { data, status } = await DataServer.post("api/login", {
          email: email,
          password: password,
        });
        if (status !== 200 && !data.token) throw "Login Error";

        setItem("data", encryptData(data));

        // clg
        // setItem("_token", `Bearer ${data.token}`);
        // setItem("_role", encryptData(ROLES[data.role_id]));
        // setItem("_user_id", encryptData(data.data.id));
        // setItem("_role_id", encryptData(data.data.role_id));
        // setItem("_warehouse_id", encryptData(data.warehouse_id));
        setItem("_isAuthenticated", true);
        // setItem("_profile_image", encryptData(data.data.profile_image));
        // setItem("_first_name", encryptData(data.data.first_name));
        // setItem("_last_name", encryptData(data.data.last_name));
        // setItem("_email", encryptData(data.data.email));
        // setItem("_contact_number", encryptData(data.data.contact_number));
        // setItem("_address_line1", encryptData(data.data.address_line1));
        // setItem("_address_line2", encryptData(data.data.address_line2));
        // setItem("_city", encryptData(data.data.city));
        // setItem("_country_id", encryptData(data.data.country_id));
        // setItem("_district_id", encryptData(data.data.district_id));
        // setItem("_state_id", encryptData(data.data.state_id));
        // setItem("_pin_code", encryptData(data.data.pin_code));
        // setItem("_tpi_firm_id", encryptData(data.tpi_firm_id));
        // setItem("_campus_name", encryptData(data.data.campus_name));
        // setItem("_warehouse_timeZone", encryptData(data.warehouse_timeZone));
        // setItem("_company_id", encryptData(data.data.company_id));

        dispatch(toggleLoading());
        dispatch(
          userLoginSuccess({
            ...data,
            isAuthenticated: true,
            load: false,
            role: ROLES[data.role_id],
          })
        );
      } catch (error) {
        dispatch(toggleLoading());
        dispatch(
          userLoginFail({
            isAuthenticated: false,
            load: false,
          })
        );
      }
    };

export const LogoutUser = () => async (dispatch: Function) => {
  const loginUserData = decryptData();

  if (loginUserData?.token) {
    try {
      dispatch(toggleLoading());
      const { data, status } = await DataServer.post("api/logout");

      if(status === 200){
        removeAll();
        window.location.reload();
      }
      // return data;
    } catch(error) {
      // console.log(error)
    }
  }
};
