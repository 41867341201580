import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TpiVerticalSummary {
  TpiVerticalSummary: {
    load: boolean;
    data: [];
  };
}

export const TpiVerticalSummarySlice = createSlice({
  name: "TpiVerticalSummary",
  initialState: {
    TpiVerticalSummary: {
      load: false,
      data: [],
    },
  } as TpiVerticalSummary,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiVerticalSummary.pending, (state: TpiVerticalSummary) => {
        state.TpiVerticalSummary.load = true;
      })
      .addCase(fetchTpiVerticalSummary.fulfilled, (state: TpiVerticalSummary, action: any) => {
        state.TpiVerticalSummary.data = action.payload;
        state.TpiVerticalSummary.load = false;
      })
      .addCase(fetchTpiVerticalSummary.rejected, (state: TpiVerticalSummary) => {
        state.TpiVerticalSummary.load = false;
      });
  },
});

export default TpiVerticalSummarySlice.reducer;

export const fetchTpiVerticalSummary = createAsyncThunk(
  "TpiVerticalSummary/fetchTpiVerticalSummary",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getVerticalSummary?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
