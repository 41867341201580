import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";

interface ComplianceState {
  compliance: {
    load: boolean;
    data: [];
  };
}

export const complianceSlice = createSlice({
  name: "compliance",
  initialState: {
    compliance: {
      load: false,
      data: [],
    },
  } as ComplianceState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchcompliance.pending, (state: ComplianceState) => {
        state.compliance.load = true;
      })
      .addCase(fetchcompliance.fulfilled, (state: ComplianceState, action: any) => {
        state.compliance.data = action.payload;
        state.compliance.load = false;
      })
      .addCase(fetchcompliance.rejected, (state: ComplianceState) => {
        state.compliance.load = false;
      });
  },
});

export default complianceSlice.reducer;

export const fetchcompliance = createAsyncThunk(
  "compliance/fetchcompliance",
  async (param: { apiParam: { project_pin: any, layout_id: any, Code: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/complianceData?project_pin=" + param.apiParam.project_pin + "&layout_pin=" + param.apiParam.layout_id + "&code_group=" + param.apiParam.Code);
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error in Login", error);
    }
  }
);

export const Savecompliance = createAsyncThunk(
    "compliance/Savecompliance",
    async (param: { apiParam: { project_pin: any, layout_id: any, compliance_data: any, NextTab: any } }, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.post("api/addComplianceData?compliance_data=" + JSON.stringify(param.apiParam.compliance_data));
       
        thunkAPI.dispatch(toggleLoading());
        thunkAPI.dispatch(
            activateSweetAlert({
                show: true,
                type: "success",
                title: "Success!",
                confirmBtnText: 'Done',
                message: data.message,
                onConfirm: () => {
                    handleonConfirm()
                },
            })
        );

        const handleonConfirm = () => {
            // param.navigation(`/view-tpi-report/${param.apiParam.project_pin}`);
            // window.location.reload();
            param.apiParam.NextTab("tab_5");
            thunkAPI.dispatch(deActivateSweetAlert());
        };
        return data.data;

      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
  );
