import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface SyncReportedIssuesState {
  syncReportedIssues: {
    load: boolean;
    data: [];
  };
}

export const syncReportedIssuesSlice = createSlice({
  name: "syncReportedIssues",
  initialState: {
    syncReportedIssues: {
      load: false,
      data: [],
    },
  } as SyncReportedIssuesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSyncReportedIssues.pending, (state: SyncReportedIssuesState) => {
        state.syncReportedIssues.load = true;
      })
      .addCase(fetchSyncReportedIssues.fulfilled, (state: SyncReportedIssuesState, action: any) => {
        state.syncReportedIssues.data = action.payload;
        state.syncReportedIssues.load = false;
      })
      .addCase(fetchSyncReportedIssues.rejected, (state: SyncReportedIssuesState) => {
        state.syncReportedIssues.load = false;
      });
  },
});

export default syncReportedIssuesSlice.reducer;


export const fetchSyncReportedIssues = createAsyncThunk(
  "syncReportedIssues/fetchSyncReportedIssues",
  async (param: {apiParam: {per_page: any, page: any, search: any, project_pin: any, warehouse_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getSyncTpiReportedIssueDataToPrses?warehouse_id=` + param.apiParam.warehouse_id + `&per_page=` + param.apiParam.per_page + `&page=` + param.apiParam.page + "&search=" + param.apiParam.search + "&project_pin=" + param.apiParam.project_pin);

      return data.data;
    } catch (error) {
    
      return error;
    }
  }
);


