import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ReportInspectodDetailsState {
  inspectorDetails: {
    load: boolean;
    data: [];
  };
}

export const inspectorDetailsSlice = createSlice({
  name: "inspectorDetails",
  initialState: {
    inspectorDetails: {
      load: false,
      data: [],
    },
  } as ReportInspectodDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchInspectorDetails.pending, (state: ReportInspectodDetailsState) => {
        state.inspectorDetails.load = true;
      })
      .addCase(fetchInspectorDetails.fulfilled, (state: ReportInspectodDetailsState, action: any) => {
        state.inspectorDetails.data = action.payload;
        state.inspectorDetails.load = false;
      })
      .addCase(fetchInspectorDetails.rejected, (state: ReportInspectodDetailsState) => {
        state.inspectorDetails.load = false;
      });
  },
});

export default inspectorDetailsSlice.reducer;


export const fetchInspectorDetails = createAsyncThunk(
    "report/fetchInspectorDetails",
    async (param: any) => {
      try {
        const { data } = await DataServer.post(`api/inspectorDetails?warehouse_id=` + param.apiParam.warehouse_id + "&inspection_schedule_details_id=" + param.apiParam.id + "&filter=" + param.apiParam.filter);
        return data.data;
      } catch (error) {
        return error;
      }
    }
  );
