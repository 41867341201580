import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TestingListState {
    testinglist: {
    load: boolean;
    data: [];
  };
}

export const ReportedIssueSlice = createSlice({
  name: "testinglist",
  initialState: {
    testinglist: {
      load: false,
      data: [],
    },
  } as TestingListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTestingList.pending, (state: TestingListState) => {
        state.testinglist.load = true;
      })
      .addCase(fetchTestingList.fulfilled, (state: TestingListState, action: any) => {
        state.testinglist.data = action.payload;
        state.testinglist.load = false;
      })
      .addCase(fetchTestingList.rejected, (state: TestingListState) => {
        state.testinglist.load = false;
      })
      .addCase(fetchPrsesTestingList.pending, (state: TestingListState) => {
        state.testinglist.load = true;
      })
      .addCase(fetchPrsesTestingList.fulfilled, (state: TestingListState, action: any) => {
        state.testinglist.data = action.payload;
        state.testinglist.load = false;
      })
      .addCase(fetchPrsesTestingList.rejected, (state: TestingListState) => {
        state.testinglist.load = false;
      })
  },
});

export default ReportedIssueSlice.reducer;

export const fetchTestingList = createAsyncThunk(
  "testinglist/fetchTestingList",
  async (param: {apiParam: {project_pin: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getTestingSummaryList?project_pin=` + param.apiParam.project_pin);
      
      return data.data;
    } catch (error) {
      // return error;
    }
  }
);

export const fetchPrsesTestingList = createAsyncThunk(
  "testinglist/fetchPrsesTestingList",
  async (param: { apiParam: {warehouse_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getSyncTestingSummaryList?warehouse_id=` + param.apiParam.warehouse_id);
      
      return data.data;
    } catch (error) {
      // return error;
    }
  }
);
