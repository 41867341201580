import axios from "axios";
import { API_URL } from "./config";
import { getItem, removeAll } from "./utils/getLocalStorage";
import { LogoutUser } from "./redux/slice/Auth";
import decryptData from "./reusable/decryptData";

const loginUserData = decryptData();

const authHeader = () => ({
  Authorization: `Bearer ${loginUserData?.token}`,
});

const source = axios.CancelToken.source();

const API_ENDPOINT = API_URL;
const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-allow-Origin": "*",
    "Accept": "*/*",
  },
});

class DataServer {
  static get(path = "", optionalHeader = {}, token = source.token) {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
      cancelToken: token,
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader }
    });
  }

  static patch(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader(), ...optionalHeader }
    });
  }

  static put(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PUT",
      url: path,
      data: data,
      headers: { ...authHeader(), ...optionalHeader }
    });
  }

  static delete(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader(), ...optionalHeader }
    });
  }
}

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    // console.log("API Response Error", response.data.errors);
    if (response) {
      switch (response.status) {
        case 401:
          // removeAll();
          window.location.reload();
          throw response?.data?.errors;
          break;
        case 500:
          throw `Internal server error: ${response?.data?.errors || ""}`;
        case 422:
          throw response?.data?.errors;
        default:
          throw response?.data?.errors;
      }
    }
    return Promise.reject(error);
  }
);

export { DataServer };
