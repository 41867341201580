import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface WarehouseDataState {
  WarehouseData: {
    load: boolean;
    data: [];
  };
}

export const WarehouseDataSlice = createSlice({
  name: "WarehouseData",
  initialState: {
    WarehouseData: {
      load: false,
      data: [],
    },
  } as WarehouseDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchWarehouseData.pending, (state: WarehouseDataState) => {
        state.WarehouseData.load = true;
      })
      .addCase(fetchWarehouseData.fulfilled, (state: WarehouseDataState, action: any) => {
        state.WarehouseData.data = action.payload;
        state.WarehouseData.load = false;
      })
      .addCase(fetchWarehouseData.rejected, (state: WarehouseDataState) => {
        state.WarehouseData.load = false;
      });
  },
});

export default WarehouseDataSlice.reducer;

export const fetchWarehouseData = createAsyncThunk(
  "WarehouseData/fetchWarehouseData",
  async (param: { apiParam: { project_pin: any, layout_id: any, loginuserid: any } }) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getTpiFirmWarehouseDetails?project_pin=" + param.apiParam.project_pin + "&login_user_id=" + param.apiParam.loginuserid + "&layout_id=" + param.apiParam.layout_id);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
