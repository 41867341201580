import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface TimeZoneState {
  timezone: {
    load: boolean;
    data: [];
  };
}

export const CountriesSlice = createSlice({
  name: "timezone",
  initialState: {
    timezone: {
      load: false,
      data: [],
    },
  } as TimeZoneState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeZone.pending, (state) => {
        state.timezone.load = true;
      })
      .addCase(fetchTimeZone.fulfilled, (state, action) => {
        state.timezone.data = action.payload;
        state.timezone.load = false;
      })
      .addCase(fetchTimeZone.rejected, (state) => {
        state.timezone.load = false;
      });
  },
});

export default CountriesSlice.reducer;

export const fetchTimeZone = createAsyncThunk(
  "timezone/fetchTimeZone",
  async (param: {apiParam: {country_code: any}}, thunkAPI) => {
    try {
      const { data, status } = await DataServer.post("api/getTimeZone?country_code=" + param.apiParam.country_code);
      if (status !== 200) throw "Login Error";
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
