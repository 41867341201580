import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface WarehouseListState {
    WarehouseList: {
    load: boolean;
    data: [];
  };
}

export const CouponWarehouseListSlice = createSlice({
  name: "WarehouseList",
  initialState: {
    WarehouseList: {
      load: false,
      data: [],
    },
  } as WarehouseListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchWarehouseList.pending, (state: WarehouseListState) => {
        state.WarehouseList.load = true;
      })
      .addCase(fetchWarehouseList.fulfilled, (state: WarehouseListState, action: any) => {
        state.WarehouseList.data = action.payload;
        state.WarehouseList.load = false;
      })
      .addCase(fetchWarehouseList.rejected, (state: WarehouseListState) => {
        state.WarehouseList.load = false;
      });
  },
});

export default CouponWarehouseListSlice.reducer;

export const fetchWarehouseList = createAsyncThunk(
    "WarehouseList/fetchWarehouseList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/companyWarehouses?company_id=" + param.apiParam.company_id + "&params=" + param.apiParam.params);
            thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
