import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ReportedIssuesState {
  reportedissue: {
    load: boolean;
    data: [];
  };
}

export const reportedissueSlice = createSlice({
  name: "reportedissue",
  initialState: {
    reportedissue: {
      load: false,
      data: [],
    },
  } as ReportedIssuesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchReportedIssue.pending, (state: ReportedIssuesState) => {
        state.reportedissue.load = true;
      })
      .addCase(fetchReportedIssue.fulfilled, (state: ReportedIssuesState, action: any) => {
        state.reportedissue.data = action.payload;
        state.reportedissue.load = false;
      })
      .addCase(fetchReportedIssue.rejected, (state: ReportedIssuesState) => {
        state.reportedissue.load = false;
      });
  },
});

export default reportedissueSlice.reducer;


export const fetchReportedIssue = createAsyncThunk(
    "report/fetchReportedIssue",
    async (param: { apiParam: {id : any}}) => {
      try {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        const { data } = await DataServer.post(`api/reportedIssueCurrentDate?warehouse_id=` + warehouse_id + "&inspection_schedule_details_pin=" + param.apiParam.id);
        return data.data;
      } catch (error) {
        return error;
      }
    }
  );
