import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface QrCodeState {
  QrCodes: {
    load: boolean;
    data: [];
  };
}

export const QrCodesSlice = createSlice({
  name: "QrCodes",
  initialState: {
    QrCodes: {
      load: false,
      data: [],
    },
  } as QrCodeState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchQrCodes.pending, (state: QrCodeState) => {
        state.QrCodes.load = true;
      })
      .addCase(fetchQrCodes.fulfilled, (state: QrCodeState, action: any) => {
        state.QrCodes.data = action.payload;
        state.QrCodes.load = false;
      })
      .addCase(fetchQrCodes.rejected, (state: QrCodeState) => {
        state.QrCodes.load = false;
      });
  },
});

export default QrCodesSlice.reducer;

export const fetchQrCodes = createAsyncThunk(
  "QrCodes/fetchQrCodes",
  async (param: { apiParam: { warehouse_id: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/downloadQrCode?warehouse_id=" + param.apiParam.warehouse_id);
     
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
