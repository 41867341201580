import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardInspectorListState {
    graphinspectorlist: {
        load: boolean;
        data: [];
    };
}

export const dashboardinspectorlistSlice = createSlice({
    name: "graphinspectorlist",
    initialState: {
        graphinspectorlist: {
            load: false,
            data: [],
        },
    } as DashboardInspectorListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchGraphInspectorListCounts.pending, (state: DashboardInspectorListState) => {
                state.graphinspectorlist.load = true;
            })
            .addCase(fetchGraphInspectorListCounts.fulfilled, (state: DashboardInspectorListState, action: any) => {
                state.graphinspectorlist.data = action.payload;
                state.graphinspectorlist.load = false;
            })
            .addCase(fetchGraphInspectorListCounts.rejected, (state: DashboardInspectorListState) => {
                state.graphinspectorlist.load = false;
            });
    },
});

export default dashboardinspectorlistSlice.reducer;

export const fetchGraphInspectorListCounts = createAsyncThunk(
    "graphinspectorlist/fetchGraphInspectorListCounts",
    async (param: { apiParam: {inspection_type: any} }) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            const { data } = await DataServer.post("api/getDailyInspectorList?warehouse_id=" + warehouse_id +"&graph_type=" + param.apiParam.inspection_type);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);