import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../../SweetAlert";

export interface RiskChartData {
    code: string;
    max_green_severity: string;
    min_green_severity: string;
    max_amber_severity: string;
    min_amber_severity: string;
    max_red_severity: string;
    min_red_severity: string;
    high_minor: string;
    high_significant: string;
    high_critical: string;
    high_catastophic: string;
    moderate_minor: string;
    moderate_significant: string;
    moderate_critical: string;
    moderate_catastophic: string;
    low_minor: string;
    low_significant: string;
    low_critical: string;
    low_catastophic: string;
    minimal_minor: string;
    minimal_significant: string;
    minimal_critical: string;
    minimal_catastophic: string;
    warehouse_id: string;
}

interface RiskChartState {
    riskchart: {
        load: boolean;
        data: Array<RiskChartData>;
    };
    riskChartData?: RiskChartData;
    editriskChartData: {
        load: boolean;
    };
}
const initialState = {
    riskchart: {
        load: false,
        data: [],
    },
    riskChartData: {
        code: "",
        max_green_severity: "",
        min_green_severity: "",
        max_amber_severity: "",
        min_amber_severity: "",
        max_red_severity: "",
        min_red_severity: "",
        high_minor: "",
        high_significant: "",
        high_critical: "",
        high_catastophic: "",
        moderate_minor: "",
        moderate_significant: "",
        moderate_critical: "",
        moderate_catastophic: "",
        low_minor: "",
        low_significant: "",
        low_critical: "",
        low_catastophic: "",
        minimal_minor: "",
        minimal_significant: "",
        minimal_critical: "",
        minimal_catastophic: "",
        warehouse_id: ""
    },
    editriskChartData: {
        load: false,
    },
} as RiskChartState;

export const riskchartSlice = createSlice({
    name: "riskchart",
    initialState,
    reducers: {
        resetRiskChartData: (state: RiskChartState) => {
            state.riskChartData = initialState.riskChartData;
        },
        updateData: (state: RiskChartState, action: { payload: RiskChartData }) => {
            state.riskChartData = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRiskAssessmentChart.pending, (state: RiskChartState) => {
                state.riskchart.load = true;
            })
            .addCase(fetchRiskAssessmentChart.fulfilled, (state: RiskChartState, action: any) => {
                state.riskchart.data = action.payload;
                state.riskChartData = action.payload;
                state.riskchart.load = false;
            })
            .addCase(fetchRiskAssessmentChart.rejected, (state: RiskChartState) => {
                state.riskchart.load = false;
            });
    },
});

export const { updateData, resetRiskChartData } = riskchartSlice.actions;
export default riskchartSlice.reducer;

export const fetchRiskAssessmentChart = createAsyncThunk(
    "riskchart/fetchRiskAssessmentChart",
    async (param: { apiParam: { code: any, warehouse_id: any } }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getRiskAssessmentChartData?warehouse_id=" + param.apiParam.warehouse_id + "&code=" + param.apiParam.code);
            return data.data;
        } catch (error) {
            return(error);
        }
    }
);

export const UpdateRiskAssessmentChart = createAsyncThunk(
    "riskchart/UpdateRiskAssessmentChart",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/postRiskAssessmentChartData",
                param.apiParam
            );

            // thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                // param.navigation("/element-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
                window.location.reload();
            };
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
