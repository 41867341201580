import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface ChangePasswordData {
    user_id: string;
    email: string;
    old_password: string;
    new_password: string;
    new_password_confirmation: string;
}

interface ChangePasswordState {
    ChangePassword: {
        load: boolean;
        data: Array<ChangePasswordData>;
    };
    ChangePasswordData?: ChangePasswordData;
}
const initialState = {
    ChangePassword: {
        load: false,
        data: [],
        error: [],
    },
    ChangePasswordData: {
        user_id: "",
        email: "",
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
    },
} as ChangePasswordState;

export const UpdatePasswordSlice = createSlice({
    name: "ChangePassword",
    initialState,
    reducers: {
        resetChangePasswordData: (state: ChangePasswordState) => {
            state.ChangePasswordData = initialState.ChangePasswordData;
        },
        updateChangePasswordData: (state: ChangePasswordState, action: { payload: ChangePasswordData }) => {
            state.ChangePasswordData = action.payload;
        },
    },
   
});
export const { updateChangePasswordData, resetChangePasswordData } = UpdatePasswordSlice.actions;
export default UpdatePasswordSlice.reducer;

export const postChangePassword = createAsyncThunk(
    "ChangePassword/postChangePassword",
    async (param: { apiParam: any, navigation: any}, thunkAPI) => {
        try {

            thunkAPI.dispatch(toggleLoading());

            const { data } = await DataServer.post(
                "api/changePassword", 
                param.apiParam
            );

            thunkAPI.dispatch(toggleLoading());

            if(data.message === "Old Password Doesn't match!"){
                thunkAPI.dispatch(
                    activateSweetAlert({
                        show: true,
                        type: "warning",
                        title: "Warning!",
                        confirmBtnText: 'Done',
                        message: data.message,
                        onConfirm: () => {
                            handleonConfirm()
                        },
                    })
                );
    
                const handleonConfirm = () => {
                    // param.navigation("/dashboard");
                    thunkAPI.dispatch(deActivateSweetAlert());
                };
            } else {
                thunkAPI.dispatch(
                    activateSweetAlert({
                        show: true,
                        type: "success",
                        title: "Success!",
                        confirmBtnText: 'Done',
                        message: data.message,
                        onConfirm: () => {
                            handleonConfirm()
                        },
                    })
                );
    
                const handleonConfirm = () => {
                    param.navigation("/dashboard");
                    thunkAPI.dispatch(deActivateSweetAlert());
                };
            }

            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
            return error;
        }
    }
);
