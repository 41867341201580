import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface SetupVendorState {
  setupVendor: {
    load: boolean;
    data: [];
  };
}

export const SetupVendorSlice = createSlice({
  name: "setupVendor",
  initialState: {
    setupVendor: {
      load: false,
      data: [],
    },
  } as SetupVendorState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetupVendor.pending, (state) => {
        state.setupVendor.load = true;
      })
      .addCase(fetchSetupVendor.fulfilled, (state, action) => {
        state.setupVendor.data = action.payload;
        state.setupVendor.load = false;
      })
      .addCase(fetchSetupVendor.rejected, (state) => {
        state.setupVendor.load = false;
      });
  },
});

export default SetupVendorSlice.reducer;

export const fetchSetupVendor = createAsyncThunk(
  "users/fetchSetupVendor",
  async (param: any, thunkAPI) => {
    try {
      const { data, status } = await DataServer.post("api/setupVendorList?login_user_id=" + param.apiParam.login_user_id + "&role_id=" + param.apiParam.role_id);
      if (status !== 200) throw "Login Error";
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
