import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface CouponDetailsState {
    coupondetails: {
    load: boolean;
    data: [];
  };
}

export const CouponDetailsSlice = createSlice({
  name: "coupondetails",
  initialState: {
    coupondetails: {
      load: false,
      data: [],
    },
  } as CouponDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchCouponDetails.pending, (state: CouponDetailsState) => {
        state.coupondetails.load = true;
      })
      .addCase(fetchCouponDetails.fulfilled, (state: CouponDetailsState, action: any) => {
        state.coupondetails.data = action.payload;
        state.coupondetails.load = false;
      })
      .addCase(fetchCouponDetails.rejected, (state: CouponDetailsState) => {
        state.coupondetails.load = false;
      });
  },
});

export default CouponDetailsSlice.reducer;

export const fetchCouponDetails = createAsyncThunk(
    "coupon/fetchCouponDetails",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/viewCouponDetails?coupon_code=" + param.apiParam.couponCode);
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
