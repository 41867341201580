import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface CustomRiskDataState {
    customriskdata: {
        load: boolean;
        data: [];
    };
}

export const customriskdataSlice = createSlice({
    name: "customriskdata",
    initialState: {
        customriskdata: {
            load: false,
            data: [],
        },
    } as CustomRiskDataState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchcustomriskdata.pending, (state: CustomRiskDataState) => {
                state.customriskdata.load = true;
            })
            .addCase(fetchcustomriskdata.fulfilled, (state: CustomRiskDataState, action: any) => {
                state.customriskdata.data = action.payload;
                state.customriskdata.load = false;
            })
            .addCase(fetchcustomriskdata.rejected, (state: CustomRiskDataState) => {
                state.customriskdata.load = false;
            })
    },
});

export default customriskdataSlice.reducer;

export const fetchcustomriskdata = createAsyncThunk(
    "customriskdata/fetchcustomriskdata",
    async (param: { apiParam: { code: any, warehouse_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getCustomRiskAssessmentChartData?warehouse_id=" + param.apiParam.warehouse_id + "&code=" + param.apiParam.code);
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);
