import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface CanvasJsonState {
    CanvasJson: {
        load: boolean;
        data: [];
    };
}

export const CanvasJsonSlice = createSlice({
    name: "CanvasJson",
    initialState: {
        CanvasJson: {
            load: false,
            data: [],
        },
    } as CanvasJsonState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchCanvasJson.pending, (state: CanvasJsonState) => {
                state.CanvasJson.load = true;
            })
            .addCase(fetchCanvasJson.fulfilled, (state: CanvasJsonState, action: any) => {
                state.CanvasJson.data = action.payload;
                state.CanvasJson.load = false;
            })
            .addCase(fetchCanvasJson.rejected, (state: CanvasJsonState) => {
                state.CanvasJson.load = false;
            });
    },
});

export default CanvasJsonSlice.reducer;

export const fetchCanvasJson = createAsyncThunk(
    "CanvasJson/fetchCanvasJson",
    async (param: { apiParam: { warehouse_id: any, layout_id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getCanvasJsonFile?warehouse_id=" + param.apiParam.warehouse_id + "&layout_id=" + param.apiParam.layout_id);
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
