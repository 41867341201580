import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardInspectorListState {
    inspectorlist: {
        load: boolean;
        data: [];
    };
}

export const dashboardinspectorlistSlice = createSlice({
    name: "inspectorlist",
    initialState: {
        inspectorlist: {
            load: false,
            data: [],
        },
    } as DashboardInspectorListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchInspectorListCounts.pending, (state: DashboardInspectorListState) => {
                state.inspectorlist.load = true;
            })
            .addCase(fetchInspectorListCounts.fulfilled, (state: DashboardInspectorListState, action: any) => {
                state.inspectorlist.data = action.payload;
                state.inspectorlist.load = false;
            })
            .addCase(fetchInspectorListCounts.rejected, (state: DashboardInspectorListState) => {
                state.inspectorlist.load = false;
            });
    },
});

export default dashboardinspectorlistSlice.reducer;

export const fetchInspectorListCounts = createAsyncThunk(
    "inspectorlist/fetchInspectorListCounts",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/getInspectionScheduleData?warehouse_id=" + param.apiParam.warehouse_id);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
