import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface LayoutStatisticsState {
  layoutstat: {
    load: boolean;
    data: [];
  };
}

export const layoutstatSlice = createSlice({
  name: "layoutstat",
  initialState: {
    layoutstat: {
      load: false,
      data: [],
    },
  } as LayoutStatisticsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchlayoutstat.pending, (state: LayoutStatisticsState) => {
        state.layoutstat.load = true;
      })
      .addCase(fetchlayoutstat.fulfilled, (state: LayoutStatisticsState, action: any) => {
        state.layoutstat.data = action.payload;
        state.layoutstat.load = false;
      })
      .addCase(fetchlayoutstat.rejected, (state: LayoutStatisticsState) => {
        state.layoutstat.load = false;
      });
  },
});

export default layoutstatSlice.reducer;

export const fetchlayoutstat = createAsyncThunk(
  "layoutstat/fetchlayoutstat",
  async (param: { apiParam: { layout_pin: any , warehouse_id: any } }) => {
    const warehouse_id = localStorage.getItem("_warehouse_id");
    try {
      const { data } = await DataServer.post("api/rackLevelStatistics?warehouse_id=" + param.apiParam.warehouse_id + "&layout_id=" + param.apiParam.layout_pin);

      return data;
    } catch (error) {
      return error;
    }
  }
);
