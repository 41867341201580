import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface IssueChecklistState {
  IssueChecklist: {
    load: boolean;
    data: [];
  };
}

export const IssueChecklistSlice = createSlice({
  name: "IssueChecklist",
  initialState: {
    IssueChecklist: {
      load: false,
      data: [],
    },
  } as IssueChecklistState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchIssueChecklist.pending, (state: IssueChecklistState) => {
        state.IssueChecklist.load = true;
      })
      .addCase(fetchIssueChecklist.fulfilled, (state: IssueChecklistState, action: any) => {
        state.IssueChecklist.data = action.payload;
        state.IssueChecklist.load = false;
      })
      .addCase(fetchIssueChecklist.rejected, (state: IssueChecklistState) => {
        state.IssueChecklist.load = false;
      })
  },
});

export default IssueChecklistSlice.reducer;

export const fetchIssueChecklist = createAsyncThunk(
  "IssueChecklist/fetchIssueChecklist",
  async (param: { apiParam : {element_pin: any, element_issue_pin: any, page: any, per_page: any, warehouse_id: any} }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getSubIssueList?warehouse_id=" + param.apiParam.warehouse_id + "&element_pin=" + param.apiParam.element_pin + "&element_issue_pin=" + param.apiParam.element_issue_pin + "&page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page);
      thunkAPI.dispatch(toggleLoading());
      console.log("data", data)
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
