import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";

interface StructuralExcelState {
  StructuralExcel: {
    load: boolean;
    data: [];
  };
}

export const StructuralExcelSlice = createSlice({
  name: "StructuralExcel",
  initialState: {
    StructuralExcel: {
      load: false,
      data: [],
    },
  } as StructuralExcelState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(postStructuralExcel.pending, (state: StructuralExcelState) => {
        state.StructuralExcel.load = true;
      })
      .addCase(postStructuralExcel.fulfilled, (state: StructuralExcelState, action: any) => {
        state.StructuralExcel.data = action.payload;
        state.StructuralExcel.load = false;
      })
      .addCase(postStructuralExcel.rejected, (state: StructuralExcelState) => {
        state.StructuralExcel.load = false;
      })
      .addCase(fetchStructuralExcel.pending, (state: StructuralExcelState) => {
        state.StructuralExcel.load = true;
      })
      .addCase(fetchStructuralExcel.fulfilled, (state: StructuralExcelState, action: any) => {
        state.StructuralExcel.data = action.payload;
        state.StructuralExcel.load = false;
      })
      .addCase(fetchStructuralExcel.rejected, (state: StructuralExcelState) => {
        state.StructuralExcel.load = false;
      });
  },
});

export default StructuralExcelSlice.reducer;

export const postStructuralExcel = createAsyncThunk(
  "StructuralExcel/postStructuralExcel",
  async (param: { apiParam: { project_pin: any, layout_id: any, excel: any }, navigation: any }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());

      const { data } = await DataServer.post("api/postStrcturalAnalysisExcel", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id,
        upload_structural_analysis: param.apiParam.excel
      });

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => {
            handleonConfirm()
          },
        })
      );

      const handleonConfirm = () => {
        // param.navigation(`/view-tpi-report/${param.apiParam.project_pin}`);
        thunkAPI.dispatch(deActivateSweetAlert());
      };

      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);

export const fetchStructuralExcel = createAsyncThunk(
  "StructuralExcel/fetchStructuralExcel",
  async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getStrcturalAnalysisExcelData?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
