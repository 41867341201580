import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface TestingCountState {
    TestingCount: {
        load: boolean;
        data: [];
    };
}

export const TestingCountSlice = createSlice({
    name: "TestingCount",
    initialState: {
        TestingCount: {
            load: false,
            data: [],
        },
    } as TestingCountState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchTestingCount.pending, (state: TestingCountState) => {
                state.TestingCount.load = true;
            })
            .addCase(fetchTestingCount.fulfilled, (state: TestingCountState, action: any) => {
                state.TestingCount.data = action.payload;
                state.TestingCount.load = false;
            })
            .addCase(fetchTestingCount.rejected, (state: TestingCountState) => {
                state.TestingCount.load = false;
            });
    },
});

export default TestingCountSlice.reducer;

export const fetchTestingCount = createAsyncThunk(
    "TestingCount/fetchTestingCount",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getTestingCount?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);