import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface InventorySummaryState {
    InventorySummary: {
        load: boolean;
        data: [];
    };
}

const initialState: InventorySummaryState = {
    InventorySummary: {
      load: false,
      data: [],
    },
  };

export const InventorySummarySlice = createSlice({
    name: "InventorySummary",
    initialState,
    reducers: {
        resetInventorySummaryData: (state) => {
            state.InventorySummary.data = initialState.InventorySummary.data;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchInventorySummary.pending, (state: InventorySummaryState) => {
                state.InventorySummary.load = true;
            })
            .addCase(fetchInventorySummary.fulfilled, (state: InventorySummaryState, action: any) => {
                state.InventorySummary.data = action.payload;
                state.InventorySummary.load = false;
            })
            .addCase(fetchInventorySummary.rejected, (state: InventorySummaryState) => {
                state.InventorySummary.load = false;
            });
    },
});

export const { resetInventorySummaryData } = InventorySummarySlice.actions;
export default InventorySummarySlice.reducer;

export const fetchInventorySummary = createAsyncThunk(
    "InventorySummary/fetchInventorySummary",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/inventorySummaryReplacement?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(resetInventorySummaryData());            
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
