import React from "react";
import "../../assets/css/views/notification.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import DateConverter from "../DateConverter";
import decryptData from "../decryptData";

export default function RackHealthNotification(props: any) {
    const loginUserData = decryptData();
    const roleId = loginUserData?.role_id;

    return <div className="row schedule-notification mt-3 align-items-top">
        <div className={`col-md-1 pr-0 ${props.divwidth}`}>
            <span className="icon-span-brown"><FontAwesomeIcon icon={faUser} /></span>
        </div>
        <div className="col-md-8">
            {
                roleId === 9 || roleId === 11 ?
                    <div>
                        <p className=""><span><b>{props.data.message.first_name} {props.data.message.last_name}</b> assigned <b>Warehouse {props.data.message.warehouse_name}</b> to you for <b>Expert Third Party Inspection!</b></span></p>

                        {/* <p className="mt-2 pl-0">{ props?.data?.message === undefined || props?.NotificationData?.message === undefined  ? "No Notifications" : ""} </p> */}

                        {/* <a href="/projects"><button className="btn primary_btn mt-4">View Details</button></a> */}
                    </div> : ""
            }
            {roleId === 5 && props.data.message.inspection_type === "Daily" ?
                <div>
                    <p className=""><span><b>{props.data.message.first_name} {props.data.message.last_name}</b> has successfully created a <b>Daily Inspection</b> schedule from <b>{props.data.message.first_date}</b> to <b>{props.data.message.last_date}</b></span></p>

                    {/* <a href="/projects"><button className="btn primary_btn mt-4">View Schedule</button></a> */}
                </div> : ""
            }
            {roleId === 5 && props.data.message.rapid_inspection != undefined ?
                <div>
                    <p className=""><span><b>Warehouse {props.data.message.warehouse_name}:</b> Detailed inspection <b>cycle {parseInt(props.data.message.cycle + 1)}</b> - <b>{props.data.message.rapid_inspection}</b> of frames scanned and inspected rapidly.</span></p>

                    {/* <a href="/projects"><button className="btn primary_btn mt-4">View Schedule</button></a> */}
                </div> : ""}
            {roleId === 5 && props.data.message.inspection_type === "Detailed" ?
                <div>
                    <p className=""><span><b>{props.data.message.first_name} {props.data.message.last_name}</b> has successfully created a <b>Detailed Inspection ({props.data.message.cycle})</b> schedule from <b>{props.data.message.first_date}</b> to <b>{props.data.message.last_date}</b></span></p>

                    {/* <a href="/projects"><button className="btn primary_btn mt-4">View Schedule</button></a> */}
                </div> : ""}
            {(roleId === 5 || roleId === 6) && props.data.message.Project ?
                <div>
                    <p className=""><span>Project <b>{props.data.message.Project}</b> Sync by <b>{props.data.message.first_name} {props.data.message.last_name}</b> for Firm <b>{props.data.message.tpi_firm_name}</b></span></p>
                </div>
                : ""
            }
        </div>

        <div className="col-md-3 text-right">
            {
                roleId === 9 || roleId === 11 ? <span className="time-span pr-3">{new Date(`${props?.data?.received_at}`).toLocaleString()}</span> :
                    <span className="time-span pr-3"><DateConverter date={props?.data?.received_at}></DateConverter></span>
            }

        </div>
    </div>
}
