import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface LayoutList {
  layoutList: {
    load: boolean;
    data: [];
  };
}

export const layoutListSlice = createSlice({
  name: "layoutList",
  initialState: {
    layoutList: {
      load: false,
      data: [],
    },
  } as LayoutList,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(getLayoutList.pending, (state: LayoutList) => {
        state.layoutList.load = true;
      })
      .addCase(getLayoutList.fulfilled, (state: LayoutList, action: any) => {
        state.layoutList.data = action.payload;
        state.layoutList.load = false;
      })
      .addCase(getLayoutList.rejected, (state: LayoutList) => {
        state.layoutList.load = false;
      })
  },
});

export default layoutListSlice.reducer;

export const getLayoutList = createAsyncThunk(
  "PalleteManagment/getLayoutList",
  async (param: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getLayoutListData?warehouse_id=" + param.apiParam.warehouse_id);
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
