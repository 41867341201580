import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { useNavigate } from "react-router-dom";

interface SavePdfState {
    savepdf: {
        load: boolean;
        data: [];
    };
}

export const SavePdfSlice = createSlice({
    name: "savepdf",
    initialState: {
        savepdf: {
            load: false,
            data: [],
        },
    } as SavePdfState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchsavepdf.pending, (state: SavePdfState) => {
                state.savepdf.load = true;
            })
            .addCase(fetchsavepdf.fulfilled, (state: SavePdfState, action: any) => {
                state.savepdf.data = action.payload;
                state.savepdf.load = false;
            })
            .addCase(fetchsavepdf.rejected, (state: SavePdfState) => {
                state.savepdf.load = false;
            });
    },
});

export default SavePdfSlice.reducer;

export const postsavepdf = createAsyncThunk(
    "savepdf/postsavepdf",
    async (param: { apiParam: { project_pin: any, layout_id: any, report: any, showPopup: any, NextTab: any, NextTabValue: any}, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            
            const { data } = await DataServer.post("api/postReportPdfFile", {
                "project_pin": param.apiParam.project_pin,
                "layout_id": param.apiParam.layout_id,
                "report[]": param.apiParam.report
            });

            if(param.apiParam.showPopup === true){
                thunkAPI.dispatch(toggleLoading());
                thunkAPI.dispatch(
                    activateSweetAlert({
                        show: true,
                        type: "success",
                        title: "Success!",
                        confirmBtnText: 'Done',
                        message: data.message,
                        // onConfirm: () => {param.apiParam.NextTabValue === "" ? handleonConfirm1() : handleonConfirm()},
                        onConfirm: () => {handleonConfirm()},
                    })
                );

        
                const handleonConfirm = () => {
                    if(param.apiParam.NextTabValue === ""){
                        param.navigation(`/view-project-report`, { state: { LayoutId: param.apiParam.layout_id, ProjectPin: param.apiParam.project_pin } }, { replace: true });
                        thunkAPI.dispatch(deActivateSweetAlert());
                        window.location.reload();
                    } else {
                        param.apiParam.NextTab(`${param.apiParam.NextTabValue}`);
                        thunkAPI.dispatch(deActivateSweetAlert());
                    }
                };

                // const handleonConfirm1 = () => {
                // }
            }

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // return error;r
        }
    }
);

export const fetchsavepdf = createAsyncThunk(
    "savepdf/fetchsavepdf",
    async (param: { apiParam: { project_pin: any, layout_id: any, report: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getReportPdfFile", {
                project_pin: param.apiParam.project_pin,
                layout_id: param.apiParam.layout_id,
                report: param.apiParam.report
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
