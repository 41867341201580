import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TestReadingState {
  testreading: {
    load: boolean;
    data: [];
  };
}

export const TestReadingSlice = createSlice({
  name: "testreading",
  initialState: {
    testreading: {
      load: false,
      data: [],
    },
  } as TestReadingState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTestReading.pending, (state: TestReadingState) => {
        state.testreading.load = true;
      })
      .addCase(fetchTestReading.fulfilled, (state: TestReadingState, action: any) => {
        state.testreading.data = action.payload;
        state.testreading.load = false;
      })
      .addCase(fetchTestReading.rejected, (state: TestReadingState) => {
        state.testreading.load = false;
      })
      .addCase(fetchTpiTestReading.pending, (state: TestReadingState) => {
        state.testreading.load = true;
      })
      .addCase(fetchTpiTestReading.fulfilled, (state: TestReadingState, action: any) => {
        state.testreading.data = action.payload;
        state.testreading.load = false;
      })
      .addCase(fetchTpiTestReading.rejected, (state: TestReadingState) => {
        state.testreading.load = false;
      });
  },
});

export default TestReadingSlice.reducer;

export const fetchTestReading = createAsyncThunk(
  "testreading/fetchTestReading",
  async (param: {apiParam: {id: any, warehouse_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getReportedIssueTestReading/" + param.apiParam.id + "?warehouse_id=" + param.apiParam.warehouse_id);
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);

export const fetchTpiTestReading = createAsyncThunk(
  "testreading/fetchTpiTestReading",
  async (param: {apiParam: {id: any}}, thunkAPI) => {
    const warehouse_id = localStorage.getItem('_warehouse_id');
    try {
      const { data } = await DataServer.post("api/getTpiReportedIssueTestReading/" + param.apiParam.id);
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);



