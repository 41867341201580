import React, { FC, useEffect, useState } from "react";
import { itemProp, navbarList } from "../../utils/navbarList";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, NavLink } from "react-router-dom";
import { ROLES } from "../../utils/constant";
import { useAppSelector } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { useLocation } from "react-router-dom";
import logoimg from "../../assets/img/Racklogo.svg";
import smallLogoImg from "../../assets/img/small_logo.svg";

// interface sidebarProps { }

const Sidebar = () => {
  const userResponse = useAppSelector((state) => state.user);
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const { user } = userResponse;
  const [toggled, setToggled] = useState(false);
  const [image, setImage] = useState(false);
  // const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem('activeItem') || 'Dashboard';
  });

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('activeItem', activeItem);
  }, [activeItem]);

  const handleItemClick = (itemName: any) => {
    setActiveItem(itemName);
  };

  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };

  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    // if (location?.pathname.includes("/add-company")) {
    //   document.getElementById("companysummary")?.classList.add("active");
    // } else {
    //   // document.getElementsByClassName("pro-menu-item")?.classList.remove("active");
    // }
  }, [])

  const Item: FC<{ item: itemProp }> = ({ item }) => {
    const pathname = useLocation().pathname;

    useEffect(() => {
      
      if (pathname.includes("/verified-issue-summary") || pathname.includes("/verified-issue-details")) {
        document.getElementById("dropdown27")?.classList.add("open");
      } else if(pathname.includes("tpi-inspection-summary") || pathname.includes("tpi") || pathname.includes("compare") || pathname.includes("tpiassignproject")){
        document.getElementById("dropdown28")?.classList.add("open");
      } else if(pathname.includes("element-summary") || pathname.includes("health-parameter")){
        document.getElementById("dropdown29")?.classList.add("open");
      } else if(pathname.includes("licence-summary") || pathname.includes("add-new-licence")) {
        document.getElementById("dropdown4")?.classList.add("open");
      } else if(pathname.includes("subscription-summary") || pathname.includes("subscription-overview") || pathname.includes("subscription-setting")) {
        document.getElementById("dropdown30")?.classList.add("open");
      } else if(pathname.includes("email-notification") || pathname.includes("notification-user")) {
        document.getElementById("dropdown32")?.classList.add("open");
      }
      // document.getElementsByClassName("react-slidedown")?.classList.remove("closed");
    }, []);


    return (
      <>
        {
        // item &&
          // item.map((value, index) => (
            item.child.length == 0 && item.access_role.includes(parseInt(ROLES[user.role])) && (
              <MenuItem
                icon={item.icon}
                id={item.classname}
                active={location.pathname.includes(`${item.urlCommonWord}`)}
              // onClick={() => handleItemClick(value.title)}
              // selected={location.pathname === `${value.path}`}
              >
                {item.title}
                <Link to={item.path}
                />
              </MenuItem>
          //   )
          )
        }
        {
          item.child.length > 0 && item.access_role.includes(parseInt(ROLES[user.role])) && (
              <SubMenu
              title={item.title}
              className="Inner_dropdown"
              icon={item.icon}
              id={`dropdown${item.id}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              // active={activeItem === value.title}
              // onClick={() => handleItemClick(value.title)}
              >
                {
                  item.child &&
                  item.child.map((item: itemProp, index: number) => {
                    return item.access_role.includes(parseInt(ROLES[user.role])) && (
                      <MenuItem
                        active={location.pathname.includes(`${item.urlCommonWord}`)}
                        key={`submenu_${index}`}
                      // onClick={() => handleItemClick(item.title)}
                      >
                        {item.title}
                        <Link to={item.path}
                        /></MenuItem>
                    )
                  })
                }
              </SubMenu>
            )
          }
      </>
    )
  }

  return (
    <div className="sidenav-bar">
      <div className="logo_div" style={{ width: collapsed ? "60px" : "240px" }}>
        {
          collapsed ? <img src={smallLogoImg} alt="Logo" className="small_logo" /> : <img src={logoimg} alt="Logo" className="full_logo" />
        }
      </div>
      <div className="navbar_heading_div" style={{ left: collapsed ? "100px" : "270px" }}>
        <h2 className="nav_heading">RAMS by iNODE</h2>
      </div>
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        {/* Header */}
        <SidebarHeader>
          <SidebarHeader style={{ left: collapsed ? "70px" : "251px" }}>
            <div onClick={onClickMenuIcon}>
              <FontAwesomeIcon icon={faBars} />
            </div>
          </SidebarHeader>
        </SidebarHeader>
        {/* Content */}
        <SidebarContent>
          <Menu>
            {navbarList &&
              navbarList.map((value: itemProp, index: number) => (
                <Item item={value} key={index} />
              ))}
          </Menu>
        </SidebarContent>
      </ProSidebar>

    </div>
  );
};

export default Sidebar;
