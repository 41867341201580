import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface SyncInspectionStatisticsState {
  SyncInspectionStatistics: {
    load: boolean;
    data: [];
  };
}

export const SyncInspectionStatisticsSlice = createSlice({
  name: "SyncInspectionStatistics",
  initialState: {
    SyncInspectionStatistics: {
      load: false,
      data: [],
    },
  } as SyncInspectionStatisticsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSyncInspectionStatistics.pending, (state: SyncInspectionStatisticsState) => {
        state.SyncInspectionStatistics.load = true;
      })
      .addCase(fetchSyncInspectionStatistics.fulfilled, (state: SyncInspectionStatisticsState, action: any) => {
        state.SyncInspectionStatistics.data = action.payload;
        state.SyncInspectionStatistics.load = false;
      })
      .addCase(fetchSyncInspectionStatistics.rejected, (state: SyncInspectionStatisticsState) => {
        state.SyncInspectionStatistics.load = false;
      });
  },
});

export default SyncInspectionStatisticsSlice.reducer;

export const fetchSyncInspectionStatistics = createAsyncThunk(
  "SyncInspectionStatistics/fetchSyncInspectionStatistics",
  async (param: {apiParam: {project_pin: any, warehouse_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getSyncTpiCompletedInspectionGraphData?warehouse_id=" + param.apiParam.warehouse_id + "&project_pin=" + param.apiParam.project_pin);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
