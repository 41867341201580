import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TipReportTpiReportElementCountState {
  TpiReportElementCount: {
    load: boolean;
    data: [];
  };
}

const initialState: TipReportTpiReportElementCountState = {
  TpiReportElementCount: {
    load: false,
    data: [],
  },
};

export const TpiReportElementCountSlice = createSlice({
  name: "TpiReportElementCount",
  initialState,
  reducers: {
    resetTpiReportElementCountData: (state) => {
      state.TpiReportElementCount.data = initialState.TpiReportElementCount.data;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiReportElementCount.pending, (state: TipReportTpiReportElementCountState) => {
        state.TpiReportElementCount.load = true;
      })
      .addCase(fetchTpiReportElementCount.fulfilled, (state: TipReportTpiReportElementCountState, action: any) => {
        state.TpiReportElementCount.data = action.payload;
        state.TpiReportElementCount.load = false;
      })
      .addCase(fetchTpiReportElementCount.rejected, (state: TipReportTpiReportElementCountState) => {
        state.TpiReportElementCount.load = false;
      });
  },
});

export const { resetTpiReportElementCountData } = TpiReportElementCountSlice.actions;
export default TpiReportElementCountSlice.reducer;

export const fetchTpiReportElementCount = createAsyncThunk(
  "TpiReportElementCount/fetchTpiReportElementCount",
  async (param: {apiParam: { project_pin: any, layout_id: any }}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getLayoutWiseTpiElementsCount?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
      // thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(resetTpiReportElementCountData());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
