import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ManagementUserDashboardState {
    ManagementUserDashboard: {
        load: boolean;
        data: [];
    };
}

export const dashboardManagementUserDashboardSlice = createSlice({
    name: "ManagementUserDashboard",
    initialState: {
        ManagementUserDashboard: {
            load: false,
            data: [],
        },
    } as ManagementUserDashboardState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchManagementUserDashboardData.pending, (state: ManagementUserDashboardState) => {
                state.ManagementUserDashboard.load = true;
            })
            .addCase(fetchManagementUserDashboardData.fulfilled, (state: ManagementUserDashboardState, action: any) => {
                state.ManagementUserDashboard.data = action.payload;
                state.ManagementUserDashboard.load = false;
            })
            .addCase(fetchManagementUserDashboardData.rejected, (state: ManagementUserDashboardState) => {
                state.ManagementUserDashboard.load = false;
            });
    },
});

export default dashboardManagementUserDashboardSlice.reducer;

export const fetchManagementUserDashboardData = createAsyncThunk(
    "ManagementUserDashboard/fetchManagementUserDashboardData",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/managementUserDashboard?user_id=" + param.apiParam.userid);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
