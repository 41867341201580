import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface StructuralImagesState {
  StructuralImages: {
    load: boolean;
    data: [];
  };
}

export const StructuralImagesSlice = createSlice({
  name: "StructuralImages",
  initialState: {
    StructuralImages: {
      load: false,
      data: [],
    },
  } as StructuralImagesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      // .addCase(postStructuralImages.pending, (state: StructuralImagesState) => {
      //   state.StructuralImages.load = true;
      // })
      // .addCase(postStructuralImages.fulfilled, (state: StructuralImagesState, action: any) => {
      //   state.StructuralImages.data = action.payload;
      //   state.StructuralImages.load = false;
      // })
      // .addCase(postStructuralImages.rejected, (state: StructuralImagesState) => {
      //   state.StructuralImages.load = false;
      // })
      .addCase(fetchStructuralImages.pending, (state: StructuralImagesState) => {
        state.StructuralImages.load = true;
      })
      .addCase(fetchStructuralImages.fulfilled, (state: StructuralImagesState, action: any) => {
        state.StructuralImages.data = action.payload;
        state.StructuralImages.load = false;
      })
      .addCase(fetchStructuralImages.rejected, (state: StructuralImagesState) => {
        state.StructuralImages.load = false;
      });;
  },
});

export default StructuralImagesSlice.reducer;

export const postStructuralImages = createAsyncThunk(
  "StructuralImages/postStructuralImages",
  async (param: {apiParam: {project_pin: any, layout_id: any, image_name: any, NextTab: any}, navigation: any}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());

      const { data } = await DataServer.post("api/postStructuralImagesTpiReport", {
        "project_pin": param.apiParam.project_pin,
        "layout_id": param.apiParam.layout_id,
        "image_name[]": param.apiParam.image_name
      });

      thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: "Structural images saved successfully.",
          onConfirm: () => {
            handleonConfirm()
          },
        })
        );
        
        const handleonConfirm = () => {
          // param.navigation(`/view-tpi-report/${param.apiParam.project_pin}`);
          param.apiParam.NextTab("tab_4");
          thunkAPI.dispatch(deActivateSweetAlert());
        };

      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);

export const fetchStructuralImages = createAsyncThunk(
    "StructuralImages/fetchStructuralImages",
    async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.post("api/getStructuralImages?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
        thunkAPI.dispatch(toggleLoading());
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
  );
