import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TestingDetailsState {
    testingdetails: {
    load: boolean;
    data: [];
  };
}

export const ReportedIssueSlice = createSlice({
  name: "testingdetails",
  initialState: {
    testingdetails: {
      load: false,
      data: [],
    },
  } as TestingDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTestingDetails.pending, (state: TestingDetailsState) => {
        state.testingdetails.load = true;
      })
      .addCase(fetchTestingDetails.fulfilled, (state: TestingDetailsState, action: any) => {
        state.testingdetails.data = action.payload;
        state.testingdetails.load = false;
      })
      .addCase(fetchTestingDetails.rejected, (state: TestingDetailsState) => {
        state.testingdetails.load = false;
      })
  },
});

export default ReportedIssueSlice.reducer;

export const fetchTestingDetails = createAsyncThunk(
  "testingdetails/fetchTestingDetails",
  async (param: {apiParam: {test_pin: any}}, thunkAPI) => {
    const warehouse_id = localStorage.getItem("_warehouse_id");
    try {
      const { data } = await DataServer.post(`api/viewTestReadingDetails?test_pin=` + param.apiParam.test_pin);
      
      return data.data;
    } catch (error) {
      return error;
    }
  }
);


