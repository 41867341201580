import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface PalletOverloadingData {
    palletOverloadinData: {
        load: boolean;
        data: [];
    };
}

export const palletOverloadinDataSlice = createSlice({
    name: "palletOverloadinData",
    initialState: {
        palletOverloadinData: {
            load: false,
            data: [],
        },
    } as PalletOverloadingData,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(getPalletOverloadingData.pending, (state: PalletOverloadingData) => {
                state.palletOverloadinData.load = true;
            })
            .addCase(getPalletOverloadingData.fulfilled, (state: PalletOverloadingData, action: any) => {
                state.palletOverloadinData.data = action.payload;
                state.palletOverloadinData.load = false;
            })
            .addCase(getPalletOverloadingData.rejected, (state: PalletOverloadingData) => {
                state.palletOverloadinData.load = false;
            })
    },
});

export default palletOverloadinDataSlice.reducer;

export const getPalletOverloadingData = createAsyncThunk(
    "PalleteManagment/getPalletOverloadingData",
    async (param: any, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getPalletOverloadingData?warehouse_id=" + param.apiParam.warehouse_id + "&layout_id=" + param.apiParam.layout_id + "&rack_pin=" + param.apiParam.rack_pin);
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);
