import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface RackRunState {
  RackRun: {
    load: boolean;
    data: [];
  };
}

export const RackRunSlice = createSlice({
  name: "RackRun",
  initialState: {
    RackRun: {
      load: false,
      data: [],
    },
  } as RackRunState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackRun.pending, (state: RackRunState) => {
        state.RackRun.load = true;
      })
      .addCase(fetchRackRun.fulfilled, (state: RackRunState, action: any) => {
        state.RackRun.data = action.payload;
        state.RackRun.load = false;
      })
      .addCase(fetchRackRun.rejected, (state: RackRunState) => {
        state.RackRun.load = false;
      });
  },
});

export default RackRunSlice.reducer;

export const fetchRackRun = createAsyncThunk(
  "RackRun/fetchRackRun",
  async (param: { apiParam: { sub_layout_id: any } }) => {
    const warehouse_id = localStorage.getItem("_warehouse_id");
    try {
      const { data } = await DataServer.post("api/getRackRunsData?warehouse_id=" + warehouse_id + "&sub_layout_id=" + param.apiParam.sub_layout_id);
   
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
