import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";
import LayoutContainer from "../layout";
import { ROLES } from "../utils/constant";
import { getItem } from "../utils/getLocalStorage";

interface protextedRoute {
  children: JSX.Element;
  roles: ROLES[];
}
const ProtectedRoute: FC<protextedRoute> = ({ children, roles }) => {
  let location = useLocation();

  const { user } = useAppSelector((state) => state.user);

  const { role } = user;
  const isAuthenticated = getItem("_isAuthenticated") === "true" || false;


  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  const roleValue: number = parseInt(ROLES[role]);
  const userHasRequiredRole = roles && roles.includes(roleValue) ? true : false;

  if (isAuthenticated && !userHasRequiredRole) {
    return <a href="/403" />;
  }

  return <LayoutContainer>{children}</LayoutContainer>;
};

export default ProtectedRoute;
