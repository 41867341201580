import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ProjectListState {
    ProjectList: {
        load: boolean;
        data: [];
    };
}

export const ProjectListSlice = createSlice({
    name: "ProjectList",
    initialState: {
        ProjectList: {
            load: false,
            data: [],
        },
    } as ProjectListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchProjectList.pending, (state: ProjectListState) => {
                state.ProjectList.load = true;
            })
            .addCase(fetchProjectList.fulfilled, (state: ProjectListState, action: any) => {
                state.ProjectList.data = action.payload;
                state.ProjectList.load = false;
            })
            .addCase(fetchProjectList.rejected, (state: ProjectListState) => {
                state.ProjectList.load = false;
            })
            .addCase(fetchProjectDetails.pending, (state: ProjectListState) => {
                state.ProjectList.load = true;
            })
            .addCase(fetchProjectDetails.fulfilled, (state: ProjectListState, action: any) => {
                state.ProjectList.data = action.payload;
                state.ProjectList.load = false;
            })
            .addCase(fetchProjectDetails.rejected, (state: ProjectListState) => {
                state.ProjectList.load = false;
            });
    },
});

export default ProjectListSlice.reducer;

export const fetchProjectList = createAsyncThunk(
    "ProjectList/fetchProjectList",
    async (param: { apiParam: { per_page: any, status: any, page: any, search: any, tpi_firm_id: any }}, thunkAPI ) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/projectsummary?tpi_firm_id=" + param.apiParam.tpi_firm_id + "&per_page=" + param.apiParam.per_page + "&status=" + param.apiParam.status + "&page=" + param.apiParam.page+ "&search=" + param.apiParam.search);
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchProjectDetails = createAsyncThunk(
    "ProjectList/fetchProjectDetails",
    async (param: { apiParam: { id: any }}, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getWarehouseData/" + param.apiParam.id);
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
