import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardDetailedInspectorListState {
    detailedinspectorlist: {
        load: boolean;
        data: [];
    };
}

export const dashboardinspectorlistSlice = createSlice({
    name: "detailedinspectorlist",
    initialState: {
        detailedinspectorlist: {
            load: false,
            data: [],
        },
    } as DashboardDetailedInspectorListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchGraphInspectorDetailed.pending, (state: DashboardDetailedInspectorListState) => {
                state.detailedinspectorlist.load = true;
            })
            .addCase(fetchGraphInspectorDetailed.fulfilled, (state: DashboardDetailedInspectorListState, action: any) => {
                state.detailedinspectorlist.data = action.payload;
                state.detailedinspectorlist.load = false;
            })
            .addCase(fetchGraphInspectorDetailed.rejected, (state: DashboardDetailedInspectorListState) => {
                state.detailedinspectorlist.load = false;
            });
    },
});

export default dashboardinspectorlistSlice.reducer;


export const fetchGraphInspectorDetailed = createAsyncThunk(
    "detailedinspectorlist/fetchGraphInspectorDetailed",
    async (param: { apiParam: {inspection_type: any, warehouse_id: any} }) => {
        try {
            const { data } = await DataServer.post("api/getDailyInspectorList?warehouse_id=" + param.apiParam.warehouse_id +"&graph_type=" + param.apiParam.inspection_type);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
