import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TipElementCountState {
  ElementCount: {
    load: boolean;
    data: [];
  };
}

export const ElementCountSlice = createSlice({
  name: "ElementCount",
  initialState: {
    ElementCount: {
      load: false,
      data: [],
    },
  } as TipElementCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchElementCount.pending, (state: TipElementCountState) => {
        state.ElementCount.load = true;
      })
      .addCase(fetchElementCount.fulfilled, (state: TipElementCountState, action: any) => {
        state.ElementCount.data = action.payload;
        state.ElementCount.load = false;
      })
      .addCase(fetchElementCount.rejected, (state: TipElementCountState) => {
        state.ElementCount.load = false;
      });
  },
});

export default ElementCountSlice.reducer;

export const fetchElementCount = createAsyncThunk(
  "ElementCount/fetchElementCount",
  async (param: {apiParam: { project_pin: any }}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getTpiElementsCount?project_pin=" + param.apiParam.project_pin);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
