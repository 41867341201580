import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import decryptData from "../../../../reusable/decryptData";

interface DashboardGraphState {
    dashboardgraph: {
        load: boolean;
        data: [];
    };
}

export const dashboardgraphSlice = createSlice({
    name: "dashboardgraph",
    initialState: {
        dashboardgraph: {
            load: false,
            data: [],
        },
    } as DashboardGraphState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchGraphDetails.pending, (state: DashboardGraphState) => {
                state.dashboardgraph.load = true;
            })
            .addCase(fetchGraphDetails.fulfilled, (state: DashboardGraphState, action: any) => {
                state.dashboardgraph.data = action.payload;
                state.dashboardgraph.load = false;
            })
            .addCase(fetchGraphDetails.rejected, (state: DashboardGraphState) => {
                state.dashboardgraph.load = false;
            })
            .addCase(fetchGraphDetailsOfInspector.pending, (state: DashboardGraphState) => {
                state.dashboardgraph.load = true;
            })
            .addCase(fetchGraphDetailsOfInspector.fulfilled, (state: DashboardGraphState, action: any) => {
                state.dashboardgraph.data = action.payload;
                state.dashboardgraph.load = false;
            })
            .addCase(fetchGraphDetailsOfInspector.rejected, (state: DashboardGraphState) => {
                state.dashboardgraph.load = false;
            })
            .addCase(fetchDailyInspectionStatistics.pending, (state: DashboardGraphState) => {
                state.dashboardgraph.load = true;
            })
            .addCase(fetchDailyInspectionStatistics.fulfilled, (state: DashboardGraphState, action: any) => {
                state.dashboardgraph.data = action.payload;
                state.dashboardgraph.load = false;
            })
            .addCase(fetchDailyInspectionStatistics.rejected, (state: DashboardGraphState) => {
                state.dashboardgraph.load = false;
            });
    },
});

export default dashboardgraphSlice.reducer;

export const fetchGraphDetails = createAsyncThunk(
    "dashboardgraph/fetchGraphDetails",
    async (param: { apiParam: { inspection_type: any, inspector_id: any, warehouse_id: any, layout_id: any } }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getDailyInspectionScheduleData?warehouse_id=" + param.apiParam.warehouse_id +"&graph_type=" + param.apiParam.inspection_type + "&inspector_id=" + param.apiParam.inspector_id + "&layout_id=" + param.apiParam.layout_id);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

export const fetchGraphDetailsOfInspector = createAsyncThunk(
    "dashboardgraph/fetchGraphDetailsOfInspector",
    async (param: { apiParam: { inspector_id: any, inspection_type: any, warehouse_id: any } }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getInspectionScheduleGraphData?warehouse_id=" + param.apiParam.warehouse_id +"&graph_type=" + param.apiParam.inspection_type + "&inspector_id=" + param.apiParam.inspector_id);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

export const fetchDailyInspectionStatistics = createAsyncThunk(
    "dashboardgraph/fetchDailyInspectionStatistics",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getIssueCount?warehouse_id=" + param.apiParam.warehouse_id +"&graph_type=" + "DAILY");
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
