import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../../SweetAlert";

export interface NotificationUserData {
    warehouse_id: number;
    first_name: string;
    last_name: string;
    email: string;
    designation: string;
    notification_type: string;
    user_hierarchy_level: number;
    status: string;
    action: string;
}

interface UsersState {
    notificationusers: {
        load: boolean;
        data: Array<NotificationUserData>;
        error: Array<NotificationUserData>;
    };
    notificationuserData?: NotificationUserData;
    // editNotificationUser: {
    //     load: boolean;
    // };
}
const initialState = {
    notificationusers: {
        load: false,
        data: [],
        error: [],
    },
    notificationuserData: {
        warehouse_id: 0,
        first_name: "",
        last_name: "",
        email: "",
        designation: "",
        notification_type: "",
        user_hierarchy_level: 0,
        status: "1",
        action: ""
    },
    // editNotificationUser: {
    //     load: false,
    // },
} as UsersState;

export const notificationusersSlice = createSlice({
    name: "notificationusers",
    initialState,
    reducers: {
        resetNotificationUserData: (state: UsersState) => {
            state.notificationuserData = initialState.notificationuserData;
        },
        updateNotificationUserData: (state: UsersState, action: { payload: NotificationUserData }) => {
            state.notificationuserData = action.payload;
        },
        addNotificationUserError: (state: UsersState, action: any) => {
            state.notificationusers.error = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchNotificationUsers.pending, (state: UsersState) => {
                state.notificationusers.load = true;
            })
            .addCase(fetchNotificationUsers.fulfilled, (state: UsersState, action: any) => {
                state.notificationusers.data = action.payload;
                state.notificationusers.load = false;
            })
            .addCase(fetchNotificationUsers.rejected, (state: UsersState, action: any) => {
                state.notificationusers.load = false;
            });
    },
});
export const { updateNotificationUserData, resetNotificationUserData, addNotificationUserError } = notificationusersSlice.actions;
export default notificationusersSlice.reducer;

export const fetchNotificationUsers = createAsyncThunk(
    "notificationusers/fetchNotificationUsers",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.get("api/GetNotificationUsers?warehouse_id=" + param.apiParam.warehouse_id );
            return data.data;
        } catch (error) {
            // console.log("Error", error);
        }
    }
);

export const addNotificationUser = createAsyncThunk(
    "notificationusers/addNotificationUser",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/PostAddNotificationUser",
                param.apiParam
            );

            thunkAPI.dispatch(toggleLoading());
            
            if(data.message == "User successfully added email for notifications."){
                thunkAPI.dispatch(
                    activateSweetAlert({
                        show: true,
                        type: "success",
                        title: "Success!",
                        confirmBtnText: 'Done',
                        message: data.message,
                        onConfirm: () => {
                            handleonConfirm()
                        },
                    })
                );
            } else {
                thunkAPI.dispatch(
                    activateSweetAlert({
                        show: true,
                        type: "warning",
                        title: "Warning!",
                        confirmBtnText: 'Done',
                        message: data.message,
                        onConfirm: () => {
                            handleonConfirm()
                        },
                    })
                );
            }

            const handleonConfirm = () => {
                param.navigation("/email-notification");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(notificationusersSlice.actions.addNotificationUserError(error));
            // Return the error object
            return thunkAPI.rejectWithValue(error);

            return error;
        }
    }
);

