import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface RackWiseHealthDataState {
  RackWiseHealthData: {
    load: boolean;
    data: [];
  };
}

export const RackWiseHealthDataSlice = createSlice({
  name: "RackWiseHealthData",
  initialState: {
    RackWiseHealthData: {
      load: false,
      data: [],
    },
  } as RackWiseHealthDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackWiseHealthData.pending, (state: RackWiseHealthDataState) => {
        state.RackWiseHealthData.load = true;
      })
      .addCase(fetchRackWiseHealthData.fulfilled, (state: RackWiseHealthDataState, action: any) => {
        state.RackWiseHealthData.data = action.payload;
        state.RackWiseHealthData.load = false;
      })
      .addCase(fetchRackWiseHealthData.rejected, (state: RackWiseHealthDataState) => {
        state.RackWiseHealthData.load = false;
      });
  },
});

export default RackWiseHealthDataSlice.reducer;

export const fetchRackWiseHealthData = createAsyncThunk(
  "RackWiseHealthData/fetchRackWiseHealthData",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getRackWiseTpiRackHealthData?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
