import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface CompleteInspectionState {
    inspectionDetails: {
        load: boolean;
        data: [];
    };
}

export const inspectionDetailsSlice = createSlice({
    name: "inspectionDetails",
    initialState: {
        inspectionDetails: {
            load: false,
            data: [],
        },
    } as CompleteInspectionState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchInspectionDetails.pending, (state: CompleteInspectionState) => {
                state.inspectionDetails.load = true;
            })
            .addCase(fetchInspectionDetails.fulfilled, (state: CompleteInspectionState, action: any) => {
                state.inspectionDetails.data = action.payload;
                state.inspectionDetails.load = false;
            })
            .addCase(fetchInspectionDetails.rejected, (state: CompleteInspectionState) => {
                state.inspectionDetails.load = false;
            });
    },
});

export default inspectionDetailsSlice.reducer;

export const fetchInspectionDetails = createAsyncThunk(
    "inspectionDetails/fetchInspectionDetails",
    async (param: { apiParam: { id: any, filter: any, warehouse_id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/viewInspectionReport/" + param.apiParam.id + "?warehouse_id=" + param.apiParam.warehouse_id + "&filter=" + param.apiParam.filter);
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
