import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";

interface MaintainanceStrategyState {
  MaintainanceStrategy: {
    load: boolean;
    data: [];
  };
}

export const MaintainanceStrategySlice = createSlice({
  name: "MaintainanceStrategy",
  initialState: {
    MaintainanceStrategy: {
      load: false,
      data: [],
    },
  } as MaintainanceStrategyState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchMaintainanceStrategy.pending, (state: MaintainanceStrategyState) => {
        state.MaintainanceStrategy.load = true;
      })
      .addCase(fetchMaintainanceStrategy.fulfilled, (state: MaintainanceStrategyState, action: any) => {
        state.MaintainanceStrategy.data = action.payload;
        state.MaintainanceStrategy.load = false;
      })
      .addCase(fetchMaintainanceStrategy.rejected, (state: MaintainanceStrategyState) => {
        state.MaintainanceStrategy.load = false;
      });
  },
});

export default MaintainanceStrategySlice.reducer;

export const fetchMaintainanceStrategy = createAsyncThunk(
  "MaintainanceStrategy/fetchMaintainanceStrategy",
  async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getMaintenanceStrategyData?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id);
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
