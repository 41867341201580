import Loadable from 'react-loadable';
import Loading from './views/pages/Loading';

export const DesignComponent = Loadable({
  loader: () => import("./views/pages/Design"),
  loading: Loading,
});

export const UsersComponent = Loadable({
  loader: () => import("./views/pages/User"),
  loading: Loading
})
export const UserComponent = Loadable({
  loader: () => import("./views/pages/User"),
  loading: Loading,
});

export const AddEditUserComponent = Loadable({
  loader: () => import("./views/pages/User/AddEditUser"),
  loading: Loading,
});

export const ViewUserComponent = Loadable({
  loader: () => import("./views/pages/User/ViewDetails"),
  loading: Loading
});

export const LoginComponent = Loadable({
  loader: () => import("./views/login"),
  loading: Loading,
});

export const DashboardComponent = Loadable({
  loader: () => import("./views/pages/Dashboard"),
  loading: Loading,
});

export const RackHealthComponent = Loadable({
  loader: () => import("./views/pages/RackHealth"),
  loading: Loading,
});

export const RackModelComponent = Loadable({
  loader: () => import("./views/pages/RackHealth/RackModel"),
  loading: Loading,
});

export const NotFoundComponent = Loadable({
  loader: () => import("./views/pages/NotFound"),
  loading: Loading,
});
export const RoleListComponent = Loadable({
  loader: () => import("./views/pages/RoleDetails/RoleSummary"),
  loading: Loading,
});

export const CompanyListComponent = Loadable({
  loader: () => import("./views/pages/Company/CompanyList"),
  loading: Loading,
});

export const CompanyDetails = Loadable({
  loader: () => import("./views/pages/Company/CompanyDetails"),
  loading: Loading,
});

export const InspectionScheduleComponent = Loadable({
  loader: () => import("./views/pages/InspectionSchedule"),
  loading: Loading,
});

export const InspectionCalenderComponent = Loadable({
  loader: () => import("./views/pages/InspectionSchedule/InspectionCalender"),
  loading: Loading,
});

export const ReportedIssuesComponent = Loadable({
  loader: () => import("./views/pages/Issues/ReportedIssues"),
  loading: Loading,
});

export const ReportedIssuesDetailsComponent = Loadable({
  loader: () => import("./views/pages/Issues/ReportedIssueDetails"),
  loading: Loading,
});

export const CompareStateDetailsComponent = Loadable({
  loader: () => import("./views/pages/Issues/CompareStateDetails"),
  loading: Loading,
});

export const VerifiedIssuesComponent = Loadable({
  loader: () => import("./views/pages/Issues/VerifiedIssues"),
  loading: Loading,
});

export const IssueDetailsComponent = Loadable({
  loader: () => import("./views/pages/Issues/IssueDetails"),
  loading: Loading,
});

export const AddEditCompanyComponent = Loadable({
  loader: () => import("./views/pages/Company/AddEditCompany"),
  loading: Loading,
});

export const LicenceListComponent = Loadable({
  loader: () => import("./views/pages/Licence/LicenceSummary"),
  loading: Loading,
});

export const AddEditLicenceComponent = Loadable({
  loader: () => import("./views/pages/Licence/AddEditLicence"),
  loading: Loading,
});

export const QuotationSummaryComponent = Loadable({
  loader: () => import("./views/pages/Quotation/QuotationList"),
  loading: Loading,
});

export const AddEditQuotationComponent = Loadable({
  loader: () => import("./views/pages/Quotation/AddEditQuotation"),
  loading: Loading,
});

export const AddLicenceByQuotationComponent = Loadable({
  loader: () => import("./views/pages/Licence/CreateLicenceByQuotation"),
  loading: Loading,
});
export const ProfileNew = Loadable({
  loader: () => import("./views/pages/ProfileNew"),
  loading: Loading,
});
export const Projects = Loadable({
  loader: () => import("./views/pages/Projects"),
  loading: Loading,
});
export const AssignProject = Loadable({
  loader: () => import("./views/pages/Projects/AssignProject"),
  loading: Loading,
});

export const CompleteInspection = Loadable({
  loader: () => import("./views/pages/Inspection"),
  loading: Loading,
});
export const CompleteInspectionDetails = Loadable({
  loader: () => import("./views/pages/Inspection/InspectionDetails"),
  loading: Loading,
});
export const TPIInspectionSummary = Loadable({
  loader: () => import("./views/pages/Inspection/TpiInspectionSummary"),
  loading: Loading,
});
export const QrCodes = Loadable({
  loader: () => import("./views/pages/QrCodes"),
  loading: Loading,
});

export const ProjectDetails = Loadable({
  loader: () => import("./views/pages/Projects/ProjectDetails"),
  loading: Loading,
});

export const TpiAssignProject = Loadable({
  loader: () => import("./views/pages/Projects/TpiAssignProject"),
  loading: Loading,
});

export const InspectionDetails = Loadable({
  loader: () => import("./views/pages/InspectionDetails"),
  loading: Loading,
});

export const DailyReport = Loadable({
  loader: () => import("./views/pages/Inspection/DailyReport"),
  loading: Loading,
});

export const UnauthorizedRequest = Loadable({
  loader: () => import("./views/pages/UnauthorizedRequest"),
  loading: Loading,
});

export const Forbidden = Loadable({
  loader: () => import("./views/pages/Forbidden"),
  loading: Loading,
});

export const TpiReportedIssuesDetails = Loadable({
  loader: () => import("./views/pages/Issues/TpiReportedIssueDetails"),
  loading: Loading,
});

export const Settings = Loadable({
  loader: () => import("./views/pages/Settings"),
  loading: Loading,
});

export const Maintainance = Loadable({
  loader: () => import("./views/pages/Issues/Maintainance"),
  loading: Loading,
});

export const VerifiedIssueDetails = Loadable({
  loader: () => import("./views/pages/Issues/VerifiedIssueDetails"),
  loading: Loading,
});

export const ElementSummary = Loadable({
  loader: () => import("./views/pages/MasterList/ElementSummary"),
  loading: Loading,
});

export const HealthParameter = Loadable({
  loader: () => import("./views/pages/MasterList/HealthParameter"),
  loading: Loading,
});

export const RiskHealthParameter = Loadable({
  loader: () => import("./views/pages/MasterList/RiskHealthParameter"),
  loading: Loading,
});

export const SuperAdminElementSummary = Loadable({
  loader: () => import("./views/pages/MasterList/SuperAdminElementSummary"),
  loading: Loading,
});

export const SuperAdminHealthParameter = Loadable({
  loader: () => import("./views/pages/MasterList/SuperAdminHealthParameter"),
  loading: Loading,
});

export const PrsesInspectionStatistics = Loadable({
  loader: () => import("./views/pages/InspectionDetails/InspectionStatisticsPrses"),
  loading: Loading,
});

export const CompareReportedIssuesTpiPrses = Loadable({
  loader: () => import("./views/pages/Issues/AllComparedReportedIssues"),
  loading: Loading,
});

export const TPIFullReport = Loadable({
  loader: () => import("./views/pages/TPIFullReport"),
  loading: Loading,
});

export const GenerateTpiReport = Loadable({
  loader: () => import("./views/pages/TPIFullReport/GenerateTpiReport"),
  loading: Loading,
});

export const TpiReportTesting = Loadable({
  loader: () => import("./views/pages/TpiReportTesting"),
  loading: Loading,
});

export const TPINewReport = Loadable({
  loader: () => import("./views/pages/TPIFullReport/TpiReport"),
  loading: Loading,
});

export const FullPageCanvas = Loadable({
  loader: () => import("./views/pages/RackHealth/FullPageCanvas"),
  loading: Loading,
});

export const MovementTracking = Loadable({
  loader: () => import("./views/pages/ForkliftMonitor/MovementTracking"),
  loading: Loading,
});

export const ImpactSummary = Loadable({
  loader: () => import("./views/pages/ForkliftMonitor/ImpactSummary"),
  loading: Loading,
});

export const ImpactDetection = Loadable({
  loader: () => import("./views/pages/ForkliftMonitor/ImpactDetection"),
  loading: Loading,
});

export const Notifications = Loadable({
  loader: () => import("./views/pages/Notifications"),
  loading: Loading,
});

export const ChangePassword = Loadable({
  loader: () => import("./views/pages/ChangePassword"),
  loading: Loading,
});

export const IssueType = Loadable({
  loader: () => import("./views/pages/MasterList/IssueType"),
  loading: Loading,
});

export const SubIssueType = Loadable({
  loader: () => import("./views/pages/MasterList/SubIssueType"),
  loading: Loading,
});

export const EditIssueType = Loadable({
  loader: () => import("./views/pages/MasterList/EditIssueType"),
  loading: Loading,
});

export const EmailVerification = Loadable({
  loader: () => import("./views/pages/ResetPassword/EmailVerification"),
  loading: Loading,
});

export const ResetPassword = Loadable({
  loader: () => import("./views/pages/ResetPassword"),
  loading: Loading,
});

export const Checklist = Loadable({
  loader: () => import("./views/pages/MasterList/Checklist"),
  loading: Loading,
});

export const TestingSummaryDetails = Loadable({
  loader: () => import("./views/pages/InspectionDetails/TesingSummaryDetails"),
  loading: Loading,
});

export const PrsesTestingSummaryDetails = Loadable({
  loader: () => import("./views/pages/InspectionDetails/PrsesTestingSummaryDetails"),
  loading: Loading,
});

export const SubscriptionOverview = Loadable({
  loader: () => import("./views/pages/Subscription/SubscriptionOverview"),
  loading: Loading,
});

export const SubscriptionSetting = Loadable({
  loader: () => import("./views/pages/Subscription/SubscriptionSetting"),
  loading: Loading,
});

export const SubscriptionPlan = Loadable({
  loader: () => import("./views/pages/Subscription/SubscriptionPlan"),
  loading: Loading,
});

export const SubscriptionPlans = Loadable({
  loader: () => import("./views/pages/Subscription/SubscriptionPlanList"),
  loading: Loading,
});

export const CreatePackages = Loadable({
  loader: () => import("./views/pages/Subscription/CreatePackage"),
  loading: Loading,
});

export const PalletManagment = Loadable({
  loader: () => import("./views/pages/PalletManagment"),
  loading: Loading,
});

export const CouponSummary = Loadable({
  loader: () => import("./views/pages/Coupon/CouponSummary"),
  loading: Loading,
});

export const AddEditCoupon = Loadable({
  loader: () => import("./views/pages/Coupon/AddEditCoupon"),
  loading: Loading,
});

export const CouponDetails = Loadable({
  loader: () => import("./views/pages/Coupon/CouponDetails"),
  loading: Loading,
});

export const AssignCoupon = Loadable({
  loader: () => import("./views/pages/Coupon/AssignCoupon"),
  loading: Loading,
});

export const ManagmenntUserSummary = Loadable({
  loader: () => import("./views/pages/ManagmentUser/UserSummary"),
  loading: Loading,
});

export const AssignWarehouse = Loadable({
  loader: () => import("./views/pages/ManagmentUser/AssignWarehouse"),
  loading: Loading,
});

export const AssignedWarehouseList = Loadable({
  loader: () => import("./views/pages/ManagmentUser/AssignedWarehouseList"),
  loading: Loading,
});

export const ManagementUserDetails = Loadable({
  loader: () => import("./views/pages/ManagmentUser/ManagementUserDetails"),
  loading: Loading,
});

export const WarehouseAdminDashboard = Loadable({
  loader: () => import("./views/pages/WarehouseAdminDashboard"),
  loading: Loading,
});

export const EmailNotification = Loadable({
  loader: () => import("./views/pages/Notifications/EmailNotification"),
  loading: Loading,
});

export const AddNotificationUserComponent = Loadable({
  loader: () => import("./views/pages/User/AddNotificationUser"),
  loading: Loading,
});

export const EditNotificationUserComponent = Loadable({
  loader: () => import("./views/pages/User/UpdateNotificationUser"),
  loading: Loading,
});

export const VerifyOtpComponent = Loadable({
  loader: () => import("./views/VerifyOtp"),
  loading: Loading,
});
