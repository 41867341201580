import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface InspectionSettingData {
    inspection_schedule_flow: string;
    warehouse_id: number;
}

interface InspectionSettingState {
    inspectionsetting: {
        load: boolean;
        data: Array<InspectionSettingData>;
    };
    inspectionSettingData?: InspectionSettingData;
    // editUser: {
    //   load: boolean;
    // };
}

const initialState = {
    inspectionsetting: {
        load: false,
        data: [],
    },
    inspectionSettingData: {
        inspection_schedule_flow: "",
        warehouse_id: 0,
    },
    // editUser: {
    //   load: false,
    // },
} as InspectionSettingState;

export const inspectionSettingSlice = createSlice({
    name: "inspectionsetting",
    initialState,
    reducers: {
        resetSettingData: (state: InspectionSettingState) => {
            state.inspectionSettingData = initialState.inspectionSettingData;
        },
        updateSettingData: (state: InspectionSettingState, action: { payload: InspectionSettingData }) => {
            state.inspectionSettingData = action.payload;
        },
    },
    // extraReducers: (builder: any) => {
    //   builder
    //     .addCase(fetchUser.pending, (state: UsersState) => {
    //       state.editUser.load = true;
    //     })
    //     .addCase(fetchUser.fulfilled, (state: UsersState, action: any) => {
    //       state.userData = action.payload;
    //       state.editUser.load = false;
    //     })
    //     .addCase(fetchUser.rejected, (state: UsersState) => {
    //       state.editUser.load = false;
    //     });
    // },
});

export const { resetSettingData, updateSettingData } = inspectionSettingSlice.actions;

export default inspectionSettingSlice.reducer;


export const InspectionSetting = createAsyncThunk(
    "inspectionsetting/InspectionSetting",
    async (param: { apiParam : {inspection_schedule_flow: any, warehouse_id: any}}, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());

            const { data } = await DataServer.post(`api/postInspectionSetting?warehouse_id=` + param.apiParam.warehouse_id + "&inspection_schedule_flow=" + param.apiParam.inspection_schedule_flow);

            thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                // param.apiParam.navigation("/reported-issue-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };


        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    });



export const ReportTpiIssue = createAsyncThunk(
    "reportissue/ReportIssue",
    async (param: any, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const warehouse_id = localStorage.getItem("_warehouse_id");

            const { data } = await DataServer.post(`api/tpiReportedIssueVerify/${param.apiParam.id}`,
                param.apiParam.reportIssueData,
            );

            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/inspection-details");
                thunkAPI.dispatch(deActivateSweetAlert());
            };


        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    });
