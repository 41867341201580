import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface SyncWarehouseDataState {
  SyncWarehouseData: {
    load: boolean;
    data: [];
  };
}

export const SyncWarehouseDataSlice = createSlice({
  name: "SyncWarehouseData",
  initialState: {
    SyncWarehouseData: {
      load: false,
      data: [],
    },
  } as SyncWarehouseDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSyncWarehouseData.pending, (state: SyncWarehouseDataState) => {
        state.SyncWarehouseData.load = true;
      })
      .addCase(fetchSyncWarehouseData.fulfilled, (state: SyncWarehouseDataState, action: any) => {
        state.SyncWarehouseData.data = action.payload;
        state.SyncWarehouseData.load = false;
      })
      .addCase(fetchSyncWarehouseData.rejected, (state: SyncWarehouseDataState) => {
        state.SyncWarehouseData.load = false;
      });
  },
});

export default SyncWarehouseDataSlice.reducer;

export const fetchSyncWarehouseData = createAsyncThunk(
  "SyncWarehouseData/fetchSyncWarehouseData",
  async (param: {apiParam: {project_pin: any, warehouse_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getWarehouseSyncDataForInspectionStatistics?warehouse_id=" + param.apiParam.warehouse_id + "&project_pin=" + param.apiParam.project_pin);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
