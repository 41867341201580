import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import { FC, useEffect } from "react";
import { getItem, removeAll } from "./utils/getLocalStorage";
import { useAppDispatch } from "./hooks";
import { LogoutUser, userSessionSet } from "./redux/slice/Auth";
import IsAuthRoute from "./auth/IsAuthRoute";
import { ROLES } from "./utils/constant";
import "./assets/css/style.scss";
import {
  DashboardComponent,
  DesignComponent,
  LoginComponent,
  NotFoundComponent,
  RackHealthComponent,
  AddEditUserComponent,
  UsersComponent,
  ViewUserComponent,
  InspectionScheduleComponent,
  InspectionCalenderComponent,
  RoleListComponent,
  VerifiedIssuesComponent,
  IssueDetailsComponent,
  ReportedIssuesComponent,
  CompanyListComponent,
  AddEditCompanyComponent,
  LicenceListComponent,
  AddEditLicenceComponent,
  QuotationSummaryComponent,
  AddEditQuotationComponent,
  AddLicenceByQuotationComponent,
  ProfileNew,
  Projects,
  AssignProject,
  ReportedIssuesDetailsComponent,
  CompleteInspection,
  CompleteInspectionDetails,
  TPIInspectionSummary,
  QrCodes,
  ProjectDetails,
  TpiAssignProject,
  InspectionDetails,
  DailyReport,
  UnauthorizedRequest,
  Forbidden,
  TpiReportedIssuesDetails,
  Settings,
  VerifiedIssueDetails,
  ElementSummary,
  HealthParameter,
  SuperAdminElementSummary,
  SuperAdminHealthParameter,
  PrsesInspectionStatistics,
  CompareReportedIssuesTpiPrses,
  // TPIReport,
  CompareStateDetailsComponent,
  FullPageCanvas,
  MovementTracking,
  ImpactDetection,
  Notifications,
  ChangePassword,
  IssueType, 
  SubIssueType,
  EditIssueType,
  EmailVerification,
  ResetPassword,
  TestingSummaryDetails,
  SubscriptionOverview,
  SubscriptionSetting,
  SubscriptionPlan,
  PrsesTestingSummaryDetails,
  RiskHealthParameter,
  ImpactSummary,
  Checklist,
  CompanyDetails,
  TPIFullReport,
  PalletManagment,
  AddEditCoupon,
  CouponDetails,
  SubscriptionPlans,
  CreatePackages,
  CouponSummary,
  AssignCoupon,
  ManagmenntUserSummary,
  AssignWarehouse,
  ManagementUserDetails,
  AssignedWarehouseList,
  WarehouseAdminDashboard,
  EmailNotification,
  AddNotificationUserComponent,
  EditNotificationUserComponent,
  GenerateTpiReport,
  TpiReportTesting,
  TPINewReport,
  RackModelComponent,
  VerifyOtpComponent
} from "./routes";
import decryptData from "./reusable/decryptData";

const App: FC<{}> = () => {
  const isAuthenticated = getItem("_isAuthenticated") === "true" || false;
  const dispatch = useAppDispatch();

  const loginUserData = decryptData();

  useEffect(() => {
    dispatch(
      userSessionSet({
        role: ROLES[loginUserData?.data?.role_id],
        isAuthenticated: isAuthenticated,
        user_id: loginUserData?.data?.id,
        profile_image: loginUserData?.data?.profile_image,
      })
    );
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <IsAuthRoute>
              <LoginComponent />
            </IsAuthRoute>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <IsAuthRoute>
              <VerifyOtpComponent />
            </IsAuthRoute>
          }
        />
        <Route path="/design" element={<DesignComponent />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              roles={[
                ROLES.SUPERADMIN,
                ROLES.SUBADMIN,
                ROLES.WAREHOUSEADMIN,
                ROLES.WAREHOUSEOWNER,
                ROLES.PRSES,
                ROLES.TPIMANAGER,
                ROLES.TPIINSPECTOR,
                ROLES.TPIOWNER,
                ROLES.MANAGEMENTUSER
              ]}
            >
              <DashboardComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rack-health"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <RackHealthComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rack-model"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <RackModelComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-summary"
          element={
            <ProtectedRoute
              roles={[
                ROLES.SUPERADMIN,
                ROLES.SUBADMIN,
                ROLES.WAREHOUSEOWNER,
                ROLES.WAREHOUSEADMIN,
                ROLES.PRSES,
                ROLES.TPIMANAGER,
                ROLES.TPIOWNER
              ]}
            >
              <UsersComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-user"
          element={
            <ProtectedRoute
              roles={[
                ROLES.SUPERADMIN,
                ROLES.SUBADMIN,
                ROLES.WAREHOUSEOWNER,
                ROLES.WAREHOUSEADMIN,
                ROLES.PRSES,
                ROLES.TPIMANAGER,
                ROLES.TPIOWNER
              ]}
            >
              <AddEditUserComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-user/:id"
          element={
            <ProtectedRoute
              roles={[
                ROLES.SUPERADMIN,
                ROLES.SUBADMIN,
                ROLES.WAREHOUSEOWNER,
                ROLES.WAREHOUSEADMIN,
                ROLES.PRSES,
                ROLES.TPIMANAGER,
                ROLES.TPIOWNER
              ]}
            >
              <AddEditUserComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-setting/:id"
          element={
            <ProtectedRoute
              roles={[
                ROLES.SUPERADMIN,
                ROLES.SUBADMIN,
                ROLES.WAREHOUSEOWNER,
                ROLES.WAREHOUSEADMIN,
                ROLES.PRSES,
                ROLES.TPIMANAGER,
                ROLES.TPIOWNER,
                ROLES.MANAGEMENTUSER
              ]}
            >
              <AddEditUserComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-details/:id"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <ViewUserComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/role-summary"
          element={
            <ProtectedRoute roles={[ROLES.WAREHOUSEADMIN]}>
              <RoleListComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inspection-schedule"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <InspectionScheduleComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inspection-schedule-calender"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <InspectionCalenderComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/verified-issue-summary"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <VerifiedIssuesComponent />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/verified-issue-details/:issue_id"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <IssueDetailsComponent />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/reported-issue-summary"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <ReportedIssuesComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reported-issue-details/:issue_id"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <ReportedIssuesDetailsComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/comparestat-details/:issue_id"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <CompareStateDetailsComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tpiissue-details/:issue_id"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.PRSES, ROLES.TPIOWNER]}>
              <TpiReportedIssuesDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company-summary"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <CompanyListComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-company-details"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <CompanyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-company"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AddEditCompanyComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-company"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER, ROLES.WAREHOUSEADMIN]}>
              <AddEditCompanyComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quotation-summary"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <QuotationSummaryComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-new-quotation"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AddEditQuotationComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-quotation/:id"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AddEditQuotationComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/licence-summary"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <LicenceListComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-new-licence"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <AddEditLicenceComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-licence/:id"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <AddEditLicenceComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-licence-by-quote/:id"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AddLicenceByQuotationComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-setting"
          element={
            <ProtectedRoute
              roles={[
                ROLES.SUPERADMIN,
                ROLES.PRSES,
                ROLES.SUBADMIN,
                ROLES.WAREHOUSEADMIN,
                ROLES.TPIMANAGER,
              ]}
            >
              <ProfileNew />
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tpiassignproject"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <AssignProject />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project-details/:id"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <ProjectDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assign-project/:id"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <TpiAssignProject />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complete-inspection"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <CompleteInspection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complete-inspection-details/:id"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <CompleteInspectionDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tpi-inspection-summary"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <TPIInspectionSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qr-code"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <QrCodes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects-details/:project_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <InspectionDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/daily-report"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <DailyReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <Settings />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/maintainance"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <Maintainance />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/verified-issue-details/:issue_id"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <VerifiedIssueDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/element-summary"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <ElementSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path="/saelement-summary"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN]}>
              <SuperAdminElementSummary />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/health-parameter/:element_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <HealthParameter />
            </ProtectedRoute>
          }
        />

        <Route
          path="/element-risk-health-parameter/:element_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <RiskHealthParameter />
            </ProtectedRoute>
          }
        />
        

        <Route
          path="/element-issue-type/:element_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <IssueType />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/element-sub-issue-type/:issue_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <SubIssueType />
            </ProtectedRoute>
          }
        />

        <Route
          path="/element-edit-issue-type/:issue_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <EditIssueType />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tpi-statistics/:project_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <PrsesInspectionStatistics />
            </ProtectedRoute>
          }
        />    

        <Route
          path="/compare-statistics"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <CompareReportedIssuesTpiPrses />
            </ProtectedRoute>
          }
        />    

        {/* <Route
          path="/view-tpi-report/:project_pin"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <TPIReport />
            </ProtectedRoute>
          }
        /> */}

        <Route
          path="/view-report/:project_pin"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <TPINewReport />
            </ProtectedRoute>
          }
        />

        <Route
          path="/view-project-report"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <GenerateTpiReport />
            </ProtectedRoute>
          }
        />  

        <Route
          path="/full-page-canvas"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <FullPageCanvas />
            </ProtectedRoute>
          }
        />

        <Route
          path="/movement-tracking"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <MovementTracking />
            </ProtectedRoute>
          }
        />

        <Route
          path="/impact-summary"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <ImpactSummary />
            </ProtectedRoute>
          }
        />

        <Route
          path="/impact-detection"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <ImpactDetection />
            </ProtectedRoute>
          }
        />

        <Route
          path="/notifications"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.TPIMANAGER, ROLES.TPIOWNER, ROLES.WAREHOUSEADMIN, ROLES.SUPERADMIN, ROLES.MANAGEMENTUSER, ROLES.SUBADMIN]}>
              <Notifications />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/change-password"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.TPIMANAGER, ROLES.TPIOWNER, ROLES.WAREHOUSEADMIN, ROLES.SUBADMIN, ROLES.SUPERADMIN, ROLES.MANAGEMENTUSER]}>
              <ChangePassword />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/element-checklist/:element_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.SUPERADMIN]}>
              <Checklist />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/testing-details/:test_pin"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <TestingSummaryDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/testingdetails/:test_pin"
          element={
            <ProtectedRoute roles={[ROLES.PRSES]}>
              <PrsesTestingSummaryDetails />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/subscription-overview"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <SubscriptionOverview />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/subscription-setting"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <SubscriptionSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscription-plan"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER]}>
              <SubscriptionPlan />
            </ProtectedRoute>
          }
        />  

        <Route
          path="/subscription-summary"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN]}>
              <SubscriptionPlans />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/create-package"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN]}>
              <CreatePackages />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/pallet-management"
          element={
            <ProtectedRoute roles={[ROLES.PRSES, ROLES.WAREHOUSEADMIN]}>
              <PalletManagment />
            </ProtectedRoute>
          }
        />  

        <Route
          path="/coupon-summary"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <CouponSummary />
            </ProtectedRoute>
          }
        />  

        <Route
          path="/add-coupon"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AddEditCoupon />
            </ProtectedRoute>
          }
        />  

        <Route
          path="/edit-coupon/:id"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AddEditCoupon />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/coupon-details"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <CouponDetails />
            </ProtectedRoute>
          }
        /> 

        <Route
          path="/assign-coupon"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AssignCoupon />
            </ProtectedRoute>
          }
        />

        <Route
          path="/managment-user-summary/:id"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <ManagmenntUserSummary />
            </ProtectedRoute>
          }
        />

        <Route
          path="/assign-warehouse"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <AssignWarehouse />
            </ProtectedRoute>
          }
        />

        <Route
          path="/assigned-warehouse"
          element={
            <ProtectedRoute roles={[ROLES.MANAGEMENTUSER]}>
              <AssignedWarehouseList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/view-user-details"
          element={
            <ProtectedRoute roles={[ROLES.SUPERADMIN, ROLES.SUBADMIN]}>
              <ManagementUserDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/warehouse-dashboard"
          element={
            <ProtectedRoute roles={[ROLES.MANAGEMENTUSER]}>
              <WarehouseAdminDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/email-notification"
          element={
            <ProtectedRoute roles={[ROLES.WAREHOUSEADMIN]}>
              <EmailNotification />
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-notification-user"
          element={
            <ProtectedRoute roles={[ROLES.WAREHOUSEADMIN]}>
              <AddNotificationUserComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-notification-user"
          element={
            <ProtectedRoute roles={[ROLES.WAREHOUSEADMIN]}>
              <EditNotificationUserComponent />
            </ProtectedRoute>
          }
        />

      <Route
          path="/tpi-report-testing"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <TpiReportTesting />
            </ProtectedRoute>
          }
        />

        <Route
          path="/generate-project-report"
          element={
            <ProtectedRoute roles={[ROLES.TPIMANAGER, ROLES.TPIOWNER]}>
              <TPINewReport />
            </ProtectedRoute>
          }
        />


        <Route path="/email-verification" element={<EmailVerification />}/>
        <Route path="/reset-password" element={<ResetPassword />}/>

        <Route path="*" element={<NotFoundComponent />} />
        <Route path="/401" element={<UnauthorizedRequest />} />
        <Route path="/403" element={<Forbidden />} />
      </Routes>
    </div>
  );
};

export default App;
