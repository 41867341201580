import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartMixed } from "@fortawesome/pro-solid-svg-icons";
import {
  faGrid2,
  faUser,
  faBuildingUser,
  faFileCertificate,
  faTicket,
  faTrashCan,
  faChartUser,
  faUsers,
  faMoneyCheck,
  faGear,
  faNoteMedical,
  faHourglass,
  faSquareExclamation,
  faScrewdriverWrench,
  faUserPolice,
  faFiles,
  faMoneyCheckPen,
  faFileChartColumn,
  faCalendar,
  faForklift,
  faPalletBoxes,
  faWarehouseFull,
} from "@fortawesome/pro-regular-svg-icons";
import { ROLES } from "./constant";

export interface itemProp {
  id: number;
  title: string;
  path: string;
  access_role: ROLES[];
  icon: any;
  child: itemProp[];
  classname: string;
  urlCommonWord?: string;
}

var company_id;
const role_id = localStorage.getItem("_role_id");

if (role_id) {
  if (role_id === "11" || role_id === "9") {
    company_id = localStorage.getItem("_tpi_firm_id");
  } else {
    company_id = localStorage.getItem("_warehouse_id");
  }
}


export const navbarList: itemProp[] = [
  {
    id: 1,
    title: "Dashboard",
    path: "/dashboard",
    access_role: [
      ROLES.SUPERADMIN,
      ROLES.SUBADMIN,
      ROLES.WAREHOUSEADMIN,
      ROLES.WAREHOUSEOWNER,
      ROLES.PRSES,
      ROLES.TPIMANAGER,
      ROLES.TPIOWNER,
      ROLES.MANAGEMENTUSER
    ],
    icon: <FontAwesomeIcon icon={faGrid2} />,
    child: [],
    classname: "dashboard",
    urlCommonWord: "dashboard"
  },
  {
    id: 2,
    title: "Rack Health",
    path: "/rack-health",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faNoteMedical} />,
    child: [],
    classname: "rackhealth",
    urlCommonWord: "rack"
  },
  {
    id: 6,
    title: "Pallet Management",
    path: "/pallet-management",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faPalletBoxes} />,
    child: [],
    classname: "palletmanagement",
    urlCommonWord: "pallet"
  },
  {
    id: 16,
    title: "Forklift Monitor",
    path: "/movement-tracking",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faForklift} />,
    classname: "forklift",
    urlCommonWord: "movement",
    child: [
      // {
      //   id: 1,
      //   child: [],
      //   title: "Movement Tracking",
      //   path: "/movement-tracking",
      //   access_role: [ROLES.PRSES],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      //   classname: "inspectionsummary"
      // },
      // {
      //   id: 2,
      //   child: [],
      //   title: "Impact Detection",
      //   path: "/impact-detection",
      //   access_role: [ROLES.PRSES],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      //   classname: "tpiinspectionstatistics"
      // },
    ],
  },
  {
    id: 3,
    title: "User Summary",
    path: "/user-summary",
    access_role: [
      ROLES.WAREHOUSEADMIN,
      ROLES.WAREHOUSEOWNER,
      ROLES.PRSES,
      ROLES.SUPERADMIN,
      ROLES.TPIMANAGER,
      ROLES.TPIOWNER
    ],
    icon: <FontAwesomeIcon icon={faUsers} />,
    child: [],
    classname: "usersummary",
    urlCommonWord: "user"
  },
  // {
  //   id: 2,
  //   title: "Subadmins",
  //   path: "/user-summary",
  //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
  //   icon: <FontAwesomeIcon icon={faUser} />,
  //   child: [],
  // },
  {
    id: 4,
    title: "Licence Management",
    path: "/licence-management",
    access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER],
    icon: <FontAwesomeIcon icon={faFileCertificate} />,
    classname: "licencemanagment",    
    child: [
      {
        id: 1,
        child: [],
        title: "Warehouse licence",
        path: "/licence-summary",
        access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER],
        icon: <FontAwesomeIcon icon={faChartMixed} />,
        classname: "warehouselicencesummary",
        urlCommonWord:"licence",
      },
      // {
      //   id: 2,
      //   child: [],
      //   title: "Vendor license Summary",
      //   path: "/vendor-license-summary",
      //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      // },
      // {
      //   id: 3,
      //   child: [],
      //   title: "TPI license Summary",
      //   path: "/tpi-license-summary",
      //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      // },
    ],
  },
  {
    id: 5,
    title: "Company Summary",
    path: "/company-summary",
    access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
    child: [],
    icon: <FontAwesomeIcon icon={faBuildingUser} />,
    classname: "companysummary",
    urlCommonWord: "company"
  },
  {
    id: 6,
    title: "Company Details",
    path: `/edit-company`,
    access_role: [ROLES.TPIMANAGER, ROLES.TPIOWNER, ROLES.WAREHOUSEADMIN],
    child: [],
    icon: <FontAwesomeIcon icon={faBuildingUser} />,
    classname: "companysummary",
    urlCommonWord: "company"
  },
  {
    id: 7,
    title: "Coupon Summary",
    path: "/coupon-summary",
    access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
    child: [],
    icon: <FontAwesomeIcon icon={faMoneyCheck} />,
    classname: "couponsummary",
    urlCommonWord: "coupon"
  },
  // {
  //   id: 5,
  //   title: "Tickets",
  //   path: "/tickets",
  //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
  //   child: [],
  //   icon: <FontAwesomeIcon icon={faTicket} />,
  // },
  // {
  //   id: 6,
  //   title: "Trash Inputs",
  //   path: "/trash-inputs",
  //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
  //   child: [],
  //   icon: <FontAwesomeIcon icon={faTrashCan} />,
  // },
  // {
  //   id: 7,
  //   title: "User Activity",
  //   path: "/user-activity",
  //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
  //   child: [],
  //   icon: <FontAwesomeIcon icon={faChartUser} />,
  // },
  // {
  //   id: 8,
  //   title: "Data Analysis",
  //   path: "/data-analysis",
  //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
  //   child: [],
  //   icon: <FontAwesomeIcon icon={faChartMixed} />,
  // },
  // {
  //   id: 9,
  //   title: "Role",
  //   path: "/role-summary",
  //   access_role: [ROLES.WAREHOUSEADMIN, ROLES.WAREHOUSEOWNER],
  //   icon: <FontAwesomeIcon icon={faUser} />,
  //   child: [],
  // },
  // {
  //   id: 10,
  //   title: "User Summary",
  //   path: "/user-summary",
  //   access_role: [ROLES.WAREHOUSEADMIN, ROLES.WAREHOUSEOWNER, ROLES.PRSES,ROLES.SUPERADMIN],
  //   icon: <FontAwesomeIcon icon={faUsers} />,
  //   child: [],
  // },
  // {
  //   id: 11,
  //   title: "Subscription summary",
  //   path: "/subscription-summary",
  //   access_role: [ROLES.WAREHOUSEADMIN, ROLES.WAREHOUSEOWNER],
  //   icon: <FontAwesomeIcon icon={faMoneyCheck} />,
  //   child: [],
  // },
  // {
  //   id: 12,
  //   title: "Company profile",
  //   path: "/company-profile",
  //   access_role: [ROLES.WAREHOUSEADMIN, ROLES.WAREHOUSEOWNER],
  //   icon: <FontAwesomeIcon icon={faBuildingUser} />,
  //   child: [],
  // },

  // {
  //   id: 14,
  //   title: "Approval & Queries",
  //   path: "/approval-queries",
  //   access_role: [ROLES.PRSES],
  //   icon: <FontAwesomeIcon icon={faTicket} />,
  //   child: [],
  // },
  {
    id: 15,
    title: "Inspection Schedule",
    path: "/inspection-schedule-calender",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faCalendar} />,
    child: [],
    classname: "inspectionschedule",
    urlCommonWord: "schedule"
  },
  {
    id: 17,
    title: "Inspection Summary",
    path: "/complete-inspection",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faHourglass} />,
    child: [],
    classname: "inspectionsummary",
    urlCommonWord: "complete-inspection"
  },
  {
    id: 18,
    title: "Reported Issues",
    path: "/reported-issue-summary",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faSquareExclamation} />,
    child: [],
    classname: "reportedissues",
    urlCommonWord: "reported-issue"
  },
  // {
  //   id: 18,
  //   title: "Verified Issues",
  //   path: "/verified-issue-summary",
  //   access_role: [ROLES.PRSES],
  //   icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
  //   child: [],
  //   classname: "verifiedissues",
  // },

  // {
  //   id: 20,
  //   title: "Settings",
  //   path: "/settings",
  //   access_role: [ROLES.WAREHOUSEADMIN, ROLES.WAREHOUSEOWNER, ROLES.PRSES],
  //   icon: <FontAwesomeIcon icon={faGear} />,
  //   child: [],
  // },
  // {
  //   id: 21,
  //   title: "Add User",
  //   path: "/add-user",
  //   access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
  //   icon: <FontAwesomeIcon icon={faGear} />,
  //   child: [],
  // },
  // {
  //   id: 22,
  //   title: "Add User Details",
  //   path: "/view-user-details",
  //   access_role: [ROLES.PRSES],
  //   icon: <FontAwesomeIcon icon={faHourglass} />,
  //   child: [],
  // },
  // {
  //   id: 23,
  //   title: "Complete Inspection Details",
  //   path: "/complete-inspection-details",
  //   access_role: [ROLES.PRSES],
  //   icon: <FontAwesomeIcon icon={faHourglass} />,
  //   child: [],
  // },

  {
    id: 24,
    title: "Quotation Summary",
    path: "/quotation-summary",
    access_role: [ROLES.SUPERADMIN, ROLES.SUBADMIN],
    icon: <FontAwesomeIcon icon={faMoneyCheckPen} />,
    child: [],
    classname: "quotationsummary",
    urlCommonWord: "quotation"
  },
  {
    id: 25,
    title: "Projects",
    path: "/projects",
    access_role: [ROLES.TPIMANAGER, ROLES.TPIOWNER],
    icon: <FontAwesomeIcon icon={faFileChartColumn} />,
    child: [],
    classname: "projects",
    urlCommonWord: "project"
  },
  // {
  //   id: 26,
  //   title: "Inspection",
  //   path: "/inspection-details",
  //   access_role: [ROLES.TPIMANAGER],
  //   icon: <FontAwesomeIcon icon={faCalendar} />,
  //   child: [],
  //   classname: "inspection",
  // },
  {
    id: 27,
    title: "Maintenance",
    path: "/maintenance",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    child: [
      {
        id: 1,
        title: "Verified Issues",
        path: "/verified-issue-summary",
        access_role: [ROLES.PRSES],
        icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        child: [],
        classname: "verifiedissues",
        urlCommonWord: "verified-issue"
      },
    ],
    classname: "projects",
  },
  {
    id: 28,
    title: "TPI Summary",
    path: "",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faUserPolice} />,
    classname: "tpisummary",
    child: [
      {
        id: 11,
        child: [],
        title: "TPI Inspection Summary",
        path: "/tpi-inspection-summary",
        access_role: [ROLES.PRSES],
        icon: <FontAwesomeIcon icon={faChartMixed} />,
        classname: "inspectionsummary",
        urlCommonWord: "inspection-summary"
      },
      // {
      //   id: 12,
      //   child: [],
      //   title: "Inspection Statistics",
      //   path: "/tpi-statistics",
      //   access_role: [ROLES.PRSES],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      //   classname: "tpiinspectionstatistics"
      // },
      {
        id: 13,
        child: [],
        title: "Compared Issues",
        path: "/compare-statistics",
        access_role: [ROLES.PRSES],
        icon: <FontAwesomeIcon icon={faChartMixed} />,
        classname: "tpicomparestat",
        urlCommonWord: "compare-statistics"
      }
    ],
  },
  {
    id: 29,
    title: "Master List",
    path: "/master-list",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faFiles} />,
    classname: "masterlist",
    child: [
      {
        id: 1,
        child: [],
        title: "Element Summary",
        path: "/element-summary",
        access_role: [ROLES.PRSES],
        icon: <FontAwesomeIcon icon={faChartMixed} />,
        classname: "masterelementsummary",
        urlCommonWord: "element"
      },
      // {
      //   id: 2,
      //   child: [],
      //   title: "Element Summary",
      //   path: "/saelement-summary",
      //   access_role: [ROLES.SUPERADMIN],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      //   classname: "masterelementsummary",
      // },
    ],
  },
  {
    id: 30,
    title: "Subscription",
    path: "/subscription",
    access_role: [ROLES.SUPERADMIN, ROLES.TPIMANAGER, ROLES.TPIOWNER],
    icon: <FontAwesomeIcon icon={faMoneyCheck} />,
    classname: "subscription",
    child: [
      {
        id: 1,
        child: [],
        title: "Overview",
        path: "/subscription-overview",
        access_role: [ROLES.TPIMANAGER, ROLES.TPIOWNER],
        icon: <FontAwesomeIcon icon={faChartMixed} />,
        classname: "subscriptionoverview",
        urlCommonWord: "overview"
      },
      // {
      //   id: 2,
      //   child: [],
      //   title: "Settings",
      //   path: "/subscription-setting",
      //   access_role: [ROLES.TPIMANAGER, ROLES.TPIOWNER],
      //   icon: <FontAwesomeIcon icon={faChartMixed} />,
      //   classname: "subscriptionsetting",
      //   urlCommonWord: "subscription-setting"
      // },
      {
        id: 3,
        child: [],
        title: "Subscription Summary",
        path: "/subscription-summary",
        access_role: [ROLES.SUPERADMIN],
        icon: <FontAwesomeIcon icon={faChartMixed} />,
        classname: "packagesummary",
        urlCommonWord: "subscription"
      },
    ],
  },
  {
    id: 31,
    title: "Settings",
    path: "/settings",
    access_role: [ROLES.PRSES],
    icon: <FontAwesomeIcon icon={faGear} />,
    child: [],
    classname: "settings",
    urlCommonWord: "setting"
  },
  {
    id: 32,
    title: "Settings",
    path: "/settings1",
    access_role: [ROLES.WAREHOUSEADMIN],
    icon: <FontAwesomeIcon icon={faGear} />,
    child: [
      {
        id: 1,
        title: "Email Notification",
        path: "/email-notification",
        access_role: [ROLES.WAREHOUSEADMIN],
        icon: <FontAwesomeIcon icon={faGear} />,
        child: [],
        classname: "emailnotification",
        urlCommonWord: "notification"
      }
    ],
    classname: "settings",
    urlCommonWord: "setting"
  },
  {
    id: 33,
    title: "Assign Warehouse",
    path: "/assigned-warehouse",
    access_role: [ROLES.MANAGEMENTUSER],
    icon: <FontAwesomeIcon icon={faWarehouseFull} />,
    child: [],
    classname: "assignedwarehouse",
    urlCommonWord: "assigned"
  },
  
];
