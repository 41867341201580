import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";

interface RiskAssessmentState {
  RiskAssessment: {
    load: boolean;
    data: [];
  };
}

export const RiskAssessmentSlice = createSlice({
  name: "RiskAssessment",
  initialState: {
    RiskAssessment: {
      load: false,
      data: [],
    },
  } as RiskAssessmentState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRiskAssessment.pending, (state: RiskAssessmentState) => {
        state.RiskAssessment.load = true;
      })
      .addCase(fetchRiskAssessment.fulfilled, (state: RiskAssessmentState, action: any) => {
        state.RiskAssessment.data = action.payload;
        state.RiskAssessment.load = false;
      })
      .addCase(fetchRiskAssessment.rejected, (state: RiskAssessmentState) => {
        state.RiskAssessment.load = false;
      });
  },
});

export default RiskAssessmentSlice.reducer;

export const fetchRiskAssessment = createAsyncThunk(
  "RiskAssessment/fetchRiskAssessment",
  async (param: { apiParam: { project_pin: any, layout_id: any, code_group: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/postRackReduceRisk?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id + "&code_group=" + param.apiParam.code_group);
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
