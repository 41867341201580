import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ImpactListState {
  impactList: {
    load: boolean;
    data: [];
  };
}

export const impactListSlice = createSlice({
  name: "impactList",
  initialState: {
    impactList: {
      load: false,
      data: [],
    },
  } as ImpactListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchImpactList.pending, (state: ImpactListState) => {
        state.impactList.load = true;
      })
      .addCase(fetchImpactList.fulfilled, (state: ImpactListState, action: any) => {
        state.impactList.data = action.payload;
        state.impactList.load = false;
      })
      .addCase(fetchImpactList.rejected, (state: ImpactListState) => {
        state.impactList.load = false;
      });
  },
});

export default impactListSlice.reducer;

export const fetchImpactList = createAsyncThunk(
  "impactList/fetchImpactList",
  async (param: any, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/impactSummary?warehouse_id=" + param.apiParam.warehouse_id + "&page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page + "&search=" + param.apiParam.search);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);

