import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import NoInternetConnection from "./views/pages/NoInternetConnection";
import "./assets/css/views/responsive.scss";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const sentryToken = process.env.SENTRY_AUTH_TOKEN;

Sentry.init({
  dsn: sentryToken,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://demo.rams.digital/", "https://app.rams.digital"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <NoInternetConnection>
          <App />
        </NoInternetConnection>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
