import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface LayoutListState {
  layoutlist: {
    load: boolean;
    data: [];
  };
}

export const layoutlistSlice = createSlice({
  name: "layoutlist",
  initialState: {
    layoutlist: {
      load: false,
      data: [],
    },
  } as LayoutListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchlayoutlist.pending, (state: LayoutListState) => {
        state.layoutlist.load = true;
      })
      .addCase(fetchlayoutlist.fulfilled, (state: LayoutListState, action: any) => {
        state.layoutlist.data = action.payload;
        state.layoutlist.load = false;
      })
      .addCase(fetchlayoutlist.rejected, (state: LayoutListState) => {
        state.layoutlist.load = false;
      });
  },
});

export default layoutlistSlice.reducer;

export const fetchlayoutlist = createAsyncThunk(
  "layoutlist/fetchlayoutlist",
  async (param: any) => {
    try {
      const { data } = await DataServer.post("api/getLayoutsList?warehouse_id=" + param.apiParam.warehouse_id);

      return data.data;
    } catch (error) {
      return error;
    }
  }
);
